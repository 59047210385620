import React from 'react'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const DadosInscricao = (props) => {
    return (
        <Paper elevation={3} style={{ padding: 24, marginTop: 24 }}>
            <Grid item sm={12} lg={12} style={{ marginBottom: 10 }}>
                <Typography variant="body2"><b>Quantidade de Alunos por Faixa de Ano Letivo</b></Typography>
            </Grid>

            <Grid item sm={12} lg={12} >
                <Typography variant="body2">
                    Quantidade de Alunos Mirim 1 (2º e 3º Anos): <b>{props.inscricao.mirim1QtdAlunosPrevistos}</b>
                    &nbsp; - &nbsp;
                    <span style={{ color: 'darkblue' }}>No <b>Mirim 1</b>, sua escola poderá classificar <b>até {props.inscricao.mirim1QtdMaximaAlunosClassificaveis} alunos</b> para a 2ª Fase.</span>
                </Typography>
                <Typography variant="body2">
                    Quantidade de Alunos Mirim 2 (4º e 5º Anos): <b>{props.inscricao.mirim2QtdAlunosPrevistos}</b>
                    &nbsp; - &nbsp;
                    <span style={{ color: 'darkblue' }}>No <b>Mirim 2</b>, sua escola poderá classificar <b>até {props.inscricao.mirim2QtdMaximaAlunosClassificaveis} alunos</b> para a 2ª Fase.</span>
                </Typography>
            </Grid>
        </Paper>
    )
}

export default DadosInscricao;