import React, { Fragment } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { useKeycloak } from '@react-keycloak/web';

import AuthorizedFunction from '../security/AuthorizedFunction';
import { configureInterceptors } from '../services/API';

import authList, { path as authPath, mainPage as authMainPage, listRoutesParty } from '../variables/Links/Auth';
import menuList, { path as menuPath, mainPage as menuMainPage } from '../variables/Links/Menu';
import inscricaoList, { listRoutesPublic as inscricaoListPublic } from '../variables/Links/Inscricao';
import schoolList, { listRoutesPublic as schoolListPublic } from '../variables/Links/Escola';
import fileList from '../variables/Links/Arquivo';
import contextList from '../variables/Links/Contexto';
import emailList from '../variables/Links/Email';
import alunoList from '../variables/Links/Aluno';

import { __ESCOLA_DASHBOARD } from '../security/RoleConfiguration';

import MenuProvider from '../context/MenuContext';

export const Routes = () => {
    const [keycloak, initialized] = useKeycloak();

    const Interceptor = () => {
        configureInterceptors()              
        return <Private />
    }

    return (
            <MenuProvider>
                <BrowserRouter>
                    <Switch>
                        { initialized && keycloak.authenticated ? 
                            <Interceptor />
                        :
                            <Public />   
                        }
                    </Switch>
                </BrowserRouter>
            </MenuProvider>
    )
}

const renderPublicComponent = (component) => ( component )

const renderPrivateComponent = (component, roles) => AuthorizedFunction(roles) ? ( component ) : ( <Redirect to={{ pathname: authPath + authMainPage }} /> )

export const MountPrivateRoutes = (props) => {
    return (
        <>
            { props.routes.map((route, key) => 
                <Route 
                    key={key} 
                    path={route.path} 
                    exact={route.exact ?? true} 
                    render={() => renderPrivateComponent(<route.component />, route.roles)} 
                />
            )}
        </>
    )
}

export const MountPublicRoutes = (props) => {
    return (
        <>
            { props.routes.map((route, key) => 
                <Route 
                    key={key} 
                    path={route.path} 
                    exact={route.exact ?? true} 
                    render={() => renderPublicComponent(<route.component />)} 
                />
            )}
        </>
    )
}

export const Public = (props) => {
    return (
        <>
            <MountPublicRoutes routes={authList} />
            <MountPublicRoutes routes={listRoutesParty} />
            <MountPublicRoutes routes={schoolListPublic} />
            <MountPublicRoutes routes={inscricaoListPublic} />
        </>
    );
}

export const Private = (props) => {
    return (
        <>
            { menuList.map((sub, key) => 
                <Fragment key={key}>
                    <MountPrivateRoutes key={key} routes={sub.items} /> 
                    {sub.items.map((sub, key) => 
                        <MountPrivateRoutes key={key} routes={sub.submenus} />
                    )}
                </Fragment>
            )}

            <MountPrivateRoutes routes={inscricaoList} />
            <MountPrivateRoutes routes={schoolList} />
            <MountPrivateRoutes routes={fileList} />
            <MountPrivateRoutes routes={contextList} />
            <MountPrivateRoutes routes={emailList} />
            <MountPrivateRoutes routes={alunoList} />

            <MountPublicRoutes routes={listRoutesParty} />
            <MountPublicRoutes routes={authList} />
            
            {!AuthorizedFunction([__ESCOLA_DASHBOARD]) &&
                <Redirect path="/" exact={true} to={{ pathname: menuPath + menuMainPage }} />
            }
        </>
    );
}

export default Routes;