import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterDataProvaFase1 } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';

import { evasaoLimiterMask } from '../../helper/MaskHelper';

export class DadosDependenciasAdministrativas extends Component {
    constructor (props) {
        super(props)
        props.parentRef(this);
        this.state = {
            dependencias: [],
        }
    }

    componentDidMount () { this.props.loadContext() };
    
    // SOMA GERAL PARA ESCOLAS INSCRITAS
    sumEscolasInscritas = () => this.state.dependencias.reduce((total, dep) => total + dep.qtdEscolasInscritasTotal, 0);

    // SOMA GERAL PARA ALUNOS PREVISTOS
    sumAlunosPrevistos = () => this.state.dependencias.reduce((total, dep) => total + dep.qtdAlunosPrevistosTotal, 0);

    // SOMA GERAL PARA ALUNOS PARTICIPANTES
    sumAlunosParticipantes = () => this.state.dependencias.reduce((total, dep) => total + dep.qtdAlunosParticipantesTotal, 0);

    // CLASSIFICAVEIS
    sumAlunosClassificaveisMirim1 = () => this.state.dependencias.reduce((total, dep) => total + dep.qtdMaximaAlunosClassificaveisMirim1, 0);
    sumAlunosClassificaveisMirim2 = () => this.state.dependencias.reduce((total, dep) => total + dep.qtdMaximaAlunosClassificaveisMirim2, 0);
    sumAlunosClassificaveis = () => this.state.dependencias.reduce((total, dep) => total + dep.qtdMaximaAlunosClassificaveis, 0);

    // INSCRITOS
    sumAlunosInscritosFase2Mirim1 = () => this.state.dependencias.reduce((total, dep) => total + dep.qtdAlunosInscritosFase2Mirim1, 0);
    sumAlunosInscritosFase2Mirim2 = () => this.state.dependencias.reduce((total, dep) => total + dep.qtdAlunosInscritosFase2Mirim2, 0);
    sumAlunosInscritosFase2 = () => this.state.dependencias.reduce((total, dep) => total + dep.qtdAlunosInscritosFase2, 0);

    // PARTICIPANTES
    sumAlunosParticipantesFase2Mirim1 = () => this.state.dependencias.reduce((total, dep) => total + dep.qtdAlunosParticipantesFase2Mirim1, 0);
    sumAlunosParticipantesFase2Mirim2 = () => this.state.dependencias.reduce((total, dep) => total + dep.qtdAlunosParticipantesFase2Mirim2, 0);
    sumAlunosParticipantesFase2 = () => this.state.dependencias.reduce((total, dep) => total + dep.qtdAlunosParticipantesFase2, 0);

    // CALCULAR EVASÃO
    calcEvasao = () => this.sumAlunosPrevistos() > 0 ? 100 - ((this.sumAlunosParticipantes() * 100) / this.sumAlunosPrevistos()) : 0;

    render () {
        const columns = [
            { label: 'Dependência Administrativa', name: 'dependenciaAdministrativa', func: null, key: false, width: '7', },
            { label: 'Total de Escolas Inscritas', name: 'qtdEscolasInscritasTotal', func: null, key: false, width: '7', },
            { label: 'Fase 1 - Total de Alunos Inscritos', name: 'qtdAlunosPrevistosTotal', func: null, key: false, width: '7', },
            { label: 'Fase 1 - Total de Alunos Participantes', name: 'qtdAlunosParticipantesTotal', func: null, key: false, width: '7', },            
            { label: 'Fase 1 - Evasão Total', name: 'evasaoTotal', func: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask : () => "Prova Não Realizada", key: false, width: '7', },
            { label: 'Fase 2 - Mirim 1 - Alunos Classificaveis', name: 'qtdMaximaAlunosClassificaveisMirim1', func: null, key: false, width: '7', },
            { label: 'Fase 2 - Mirim 2 - Alunos Classificaveis', name: 'qtdMaximaAlunosClassificaveisMirim2', func: null, key: false, width: '7', },
            { label: 'Fase 2 - Total de Alunos Classificaveis', name: 'qtdMaximaAlunosClassificaveis', func: null, key: false, width: '7', },
            { label: 'Fase 2 - Mirim 1 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim1', func: null, key: false, width: '7', },
            { label: 'Fase 2 - Mirim 2 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim2', func: null, key: false, width: '7', },
            { label: 'Fase 2 - Total de Alunos Inscritos', name: 'qtdAlunosInscritosFase2', func: null, key: false, width: '7', },
            { label: 'Fase 2 - Mirim 1 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim1', func: null, key: false, width: '7', },
            { label: 'Fase 2 - Mirim 2 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim2', func: null, key: false, width: '7', },
            { label: 'Fase 2 - Total de Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2', func: null, key: false, width: '7', },
        ];
        
        const actions = [];

        const totals = [
            { column: 0, value: "Total", width: '7', },
            { column: 1, value: this.sumEscolasInscritas(), width: '7', },
            { column: 2, value: this.sumAlunosPrevistos(), width: '7', },
            { column: 3, value: this.sumAlunosParticipantes(), width: '7', },
            { column: 4, value: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask(this.calcEvasao()) : () => "Prova Não Realizada", width: '7', },
            { column: 5, value: this.sumAlunosClassificaveisMirim1(), width: '7', },
            { column: 6, value: this.sumAlunosClassificaveisMirim2(), width: '7', },
            { column: 7, value: this.sumAlunosClassificaveis(), width: '7', },
            { column: 8, value: this.sumAlunosInscritosFase2Mirim1(), width: '7', },
            { column: 9, value: this.sumAlunosInscritosFase2Mirim2(), width: '7', },
            { column: 10, value: this.sumAlunosInscritosFase2(), width: '7', },
            { column: 11, value: this.sumAlunosParticipantesFase2Mirim1(), width: '7', },
            { column: 12, value: this.sumAlunosParticipantesFase2Mirim2(), width: '7', },
            { column: 13, value: this.sumAlunosParticipantesFase2(), width: '7', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.state.dependencias}
                        actions={actions}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} />
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(DadosDependenciasAdministrativas);