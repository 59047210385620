import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Text from '../../components/Inputs/Text/Text';

import { isBlank, isBlankHelperText, isZeroParaInscricao } from '../../helper/ValidationHelper';
import { numberMask } from '../../helper/MaskHelper';

export class Fase1 extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            inscricaoMirim1QtdAlunosPrevistos: '',
            inscricaoMirim2QtdAlunosPrevistos: '',
            errors: {
                inscricaoMirim1QtdAlunosPrevistos: false,
                inscricaoMirim2QtdAlunosPrevistos: false,
            },
            helpers: {
                inscricaoMirim1QtdAlunosPrevistos: null,
                inscricaoMirim2QtdAlunosPrevistos: null,
            },
        }
        this.isValid = this.isValid.bind(this);
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            [e.target.name]: numberMask(e.target.value),
            errors: {
                inscricaoMirim1QtdAlunosPrevistos: false,
                inscricaoMirim2QtdAlunosPrevistos: false,
            },
            helpers: {
                inscricaoMirim1QtdAlunosPrevistos: null,
                inscricaoMirim2QtdAlunosPrevistos: null,
            },
        })
    }

    // VALIDAÇÕES
    isValid = () => {
        const isAmbosZerados = (this.state.inscricaoMirim1QtdAlunosPrevistos === 0 || this.state.inscricaoMirim1QtdAlunosPrevistos === '0') && (this.state.inscricaoMirim2QtdAlunosPrevistos === 0 || this.state.inscricaoMirim2QtdAlunosPrevistos === '0');

        if (isBlank(this.state.inscricaoMirim1QtdAlunosPrevistos) || isBlank(this.state.inscricaoMirim2QtdAlunosPrevistos) || isAmbosZerados) {
            this.setState({
                errors: {
                    inscricaoMirim1QtdAlunosPrevistos: isBlank(this.state.inscricaoMirim1QtdAlunosPrevistos) ? true : isAmbosZerados ? true : false,
                    inscricaoMirim2QtdAlunosPrevistos: isBlank(this.state.inscricaoMirim2QtdAlunosPrevistos) ? true : isAmbosZerados ? true : false,
                },
                helpers: {
                    inscricaoMirim1QtdAlunosPrevistos: isBlank(this.state.inscricaoMirim1QtdAlunosPrevistos) ? isBlankHelperText() : isAmbosZerados ? isZeroParaInscricao() : null,
                    inscricaoMirim2QtdAlunosPrevistos: isBlank(this.state.inscricaoMirim2QtdAlunosPrevistos) ? isBlankHelperText() : isAmbosZerados ? isZeroParaInscricao() : null,
                },
            });
            return false;
        }
        return true;
    }

    render() {
        return (
            <Grid container spacing={3} alignItems='center'>
                <Grid item sm={4} lg={4}>
                    <Text
                        required
                        name="inscricaoMirim1QtdAlunosPrevistos"
                        label="Quantidade de Alunos Mirim 1 (2º e 3º Anos)"
                        value={this.state.inscricaoMirim1QtdAlunosPrevistos}
                        onChange={this.handleChange}
                        error={this.state.errors.inscricaoMirim1QtdAlunosPrevistos}
                        helperText={this.state.helpers.inscricaoMirim1QtdAlunosPrevistos}
                        inputProps={{ maxLength:"4" }}
                    />
                </Grid>
                <Grid item sm={4} lg={4}>
                    <Text
                        required
                        name="inscricaoMirim2QtdAlunosPrevistos"
                        label="Quantidade de Alunos Mirim 2 (4º e 5º Anos)"
                        value={this.state.inscricaoMirim2QtdAlunosPrevistos}
                        onChange={this.handleChange}
                        error={this.state.errors.inscricaoMirim2QtdAlunosPrevistos}
                        helperText={this.state.helpers.inscricaoMirim2QtdAlunosPrevistos}
                        inputProps={{ maxLength:"4" }}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default Fase1;