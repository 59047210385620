import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    (theme) => (
        {
            container: {
                marginTop: theme.spacing(12),
                marginBottom: theme.spacing(8),
            },
            wrapper: {
                paddingLeft: theme.spacing(6),
                paddingRight: theme.spacing(6),
                ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),
                },
            },
            title: {
                marginTop: theme.spacing(6),
            },
            subtitle: {
                textIndent: theme.spacing(6),
            },
            textInitial: {
                marginTop: theme.spacing(4),
                textIndent: theme.spacing(6),
                textAlign: 'justify',
            },
            textAnexo: {
                marginTop: theme.spacing(2),
                textAlign: 'justify',
            },
            text: {
                marginTop: theme.spacing(2),
                textIndent: theme.spacing(6),
                textAlign: 'justify',
            },
            list: {
                listStyle: 'none',
                textIndent: theme.spacing(6),
                marginTop: theme.spacing(2),
            },
            center: {
                textAlign: 'center',
            },
            table: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
                textIndent: theme.spacing(0),
            },
            purple: {
                backgroundColor: '#E60480',
                color: 'white',
                fontWeight: '800',
            },
            blue: {
                backgroundColor: '#35B1E3',
                fontWeight: '800',
            },
            orange: {
                backgroundColor: '#FDC30B',
                fontWeight: '800',
            }   
        }
    )
);

export default useStyles;