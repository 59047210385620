import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import Cache from './Cache';

import RelatorioService from '../../services/Relatorio';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Loading from '../../components/Loading/Loading';

import DadosUfs from '../../forms/Relatorio/DadosUfs';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __RELATORIO_UFS_EXPORTAR } from '../../security/RoleConfiguration';

import { download } from '../../helper/FileHelper';

import { Layout } from '../../layouts/private/Private';

export class Ufs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ultimaChamada: '',
            loading: true,
        }
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setDadosUfs = d => this.DadosUfs = d;

    // EXPORTAÇÃO
    handleExportChange = () => {
        const f = [];
        f.push({ field: 'exportarPlanilha', value: true });
        
        this.Toast.setState({
            message: {
                message: "A Planilha está sendo gerada para Exportação.",
                type: 'info',
                open: true,
                loading: true,
                autoHide: false,
            }
        })

        RelatorioService.exportReportUfs(f)
            .then(res => download(res))
    }

    buscarUfs = (forceRefresh = false) => {
        this.setState({ loading: true });
        const filters = [{ 'field': "forceRefresh", 'value': forceRefresh }];

        RelatorioService.filtersReportUfs(filters)
            .then(res => {
                this.setState({ 
                    ultimaChamada: res.data.ultimaChamada,
                    loading: false 
                });

                this.DadosUfs.setState({ 
                    ufs: [...res.data.cache]
                });
            })
            .catch(error => {
                const e = errors(error);
                this.setState({ loading: false });
                
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
    };

    componentDidMount() {
        this.buscarUfs();
    }

    render() {
        const pageName = "UF's";
        const links = [
            { 
                path: null, 
                name: 'Relatórios' 
            }
        ];

        return(
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={pageName} />
                    </Grid>
                </Grid>
                <Title>
                    {pageName}
                    { this.state.ultimaChamada && 
                        <Cache ultimaAtualizacao={this.state.ultimaChamada} action={() => this.buscarUfs(true)} /> 
                    }
                </Title>

                <Grid container spacing={3}>
                    <AuthorizedElement roles={[__RELATORIO_UFS_EXPORTAR]}>
                        <ButtonExport
                        title="Exportar Planilha"
                        onClick={this.handleExportChange}
                        />
                    </AuthorizedElement>
                </Grid>

                { this.state.loading ? 
                    <Loading />
                :
                    <DadosUfs parentRef={this.setDadosUfs} />
                }
            </Layout>
        )
    }
}

export default withRouter(Ufs);