import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ListIcon from '@material-ui/icons/List';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import FunctionsIcon from '@material-ui/icons/Functions';
import SwapVertOutlinedIcon from '@material-ui/icons/SwapVertOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

import ContextoService from '../../services/Contexto';
import InscricaoService from '../../services/Inscricao';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from "../../components/Toast/Toast";
import { ButtonPrimary } from "../../components/Button/Button";
import { ContextDispatcher } from '../../components/Dispatcher/Dispatcher';
import Loading from '../../components/Loading/Loading';

import { makeStyles } from '@material-ui/core/styles';

import { ButtonAdd } from '../../forms/Buttons/ButtonsAdd';

import { patternContext } from '../../variables/Enums/Contexto';

import AuthorizedElement from '../../security/AuthorizedElement';
import AuthorizedFunction from '../../security/AuthorizedFunction';
import {
    __CONTEXTO_EDITAR_VALOR,
    __CONTEXTO_ADICIONAR,
    __CONTEXTO_VISUALIZAR,
    __CONTEXTO_ORDENAR_IMPRESSAO,
    __CONTEXTO_CALCULAR_MATERIAIS,
} from '../../security/RoleConfiguration';

import {
    isBlank,
    isBlankHelperText,
    isNumber,
    isDateTimeHelperText,
    isDateTimeInvalid,
    isOrdenacaoEscolasJaRealizada,
} from '../../helper/ValidationHelper';
import { formatInTimeZone } from '../../helper/DateHelper';

import { Layout } from '../../layouts/private/Private';

export const useStyles = makeStyles(theme => ({
    body: {
        margin: theme.spacing(3, 0),
    },
}));

const ButtonList = () => {
    const classes = useStyles();
    return (
        <Grid item sm={12} lg={2} className={classes.body}>
            <ButtonPrimary
                to='/admin/contexto/listagem'
                component={Link}
                startIcon={<ListIcon />}
                name="Listagem de Variáveis"
            />
        </Grid>
    )
}

class Contexto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contexts: [],
            errors: {},
            helpers: {},
            loading: true,
            loadingButtonCalc: false,
            ordenacaoJaRealizada: true,
            loadingButtonOrder: false,
        }
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            contexts: [...this.state.contexts.filter(c => {
                if (c.name === e.target.name)
                    if (c.type !== "LONG" || (c.type === "LONG" && !isNumber(e.target.value)))
                        c.value = e.target.value;
                return true
            })
            ],
            errors: { ...this.state.errors, [e.target.name]: false },
            helpers: { ...this.state.helpers, [e.target.name]: null }
        });
    };

    // INPUTS DATE TIME PICKER
    handleChangeDateTime = (e, name, key) => {
        this.setState({
            contexts: [...this.state.contexts.filter(c => {
                if (c.name === name) c.value = e;
                return true
            })
            ],
            errors: { ...this.state.errors, [name]: false },
            helpers: { ...this.state.helpers, [name]: null }
        });
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // MONTAR VARIAVEIS
    fieldsContext = () => {
        if (this.state.contexts.length > 0) {
            return (
                <Grid container spacing={3} style={{ marginTop: 8, marginBottom: 8 }} >
                    {this.state.contexts.map((c, key) => {
                        return (
                            <ContextDispatcher
                                context={c}
                                key={key}
                                required
                                label={c.label}
                                name={c.name}
                                autoComplete="off"
                                onChange={(e) => {
                                    c.type === "DATA" ? this.handleChangeDateTime(e, c.name, key) : this.handleChange(e)
                                    this.handleBlur(key)
                                }}
                                disabled={!AuthorizedFunction([__CONTEXTO_EDITAR_VALOR])}
                                value={this.state.contexts[key].value}
                                error={this.state.errors[c.name]}
                                helperText={this.state.helpers[c.name]}
                            />
                        )
                    })}
                </Grid>
            )
        }
    }

    // VALIDAÇÃO
    isValid = c => {
        if (isBlank(c.value)) {
            this.setState({
                errors: { ...this.state.errors, [c.name]: true },
                helpers: { ...this.state.helpers, [c.name]: isBlankHelperText() }
            })
            return false
        } else if (isDateTimeInvalid(c.value) && c.type === "DATA") {
            this.setState({
                errors: { ...this.state.errors, [c.name]: true },
                helpers: { ...this.state.helpers, [c.name]: isDateTimeHelperText() }
            })
            return false
        }
        return true
    }

    // SALVAR CONTEXTO
    handleBlur = (key) => {
        const c = this.state.contexts.find((context, index) => index === key);

        if (!this.isValid(c))
            return

        const context = {
            nmTipo: c.type,
            nmValor: c.type === "DATA" ? formatInTimeZone(c.value) : c.value,
            nmVariavel: c.label
        }

        ContextoService.edit(c.id, context)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Contexto Salvo com Sucesso",
                        type: "success",
                        open: true
                    }
                });
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            });
    }

    handleOrderSchools = () => {
        this.setState({ loadingButtonOrder: true });

        InscricaoService.ordenarEscolasIncritas()
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Ordenação Realizada com Sucesso",
                        type: "success",
                        open: true
                    }
                });
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingButtonOrder: false, ordenacaoJaRealizada: true }));
    }

    handleCalcDocsSchools = () => {
        this.setState({ loadingButtonCalc: true });

        InscricaoService.calcularMateriaisEscolasInscritas()
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Cálculo de Materiais Realizado com Sucesso",
                        type: "success",
                        open: true
                    }
                });
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingButtonCalc: false }));
    }

    async componentDidMount() {
        this.setState({ ordenacaoJaRealizada: await isOrdenacaoEscolasJaRealizada() });

        let f = [];
        f.push({ field: 'pageable', value: false });

        ContextoService.filters(f)
            .then(res => {
                if (res.data.content) {
                    const contexts = patternContext(res.data.content);

                    let errors = {};
                    contexts.forEach(context => { errors[context.name] = false });

                    let helpers = {};
                    contexts.forEach(context => { helpers[context.name] = null });

                    this.setState({
                        contexts: [...this.state.contexts, ...contexts],
                        errors: errors,
                        helpers: helpers,
                        loading: false,
                    });
                } else {
                    this.setState({
                        contexts: [],
                        errors: {},
                        helpers: {},
                        loading: false,
                    });
                }
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })

                this.setState({ loading: false, });
            })
    }

    render() {
        const page = 'Contexto';
        const links = [];

        return (
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>
                <Title>{page}</Title>

                <Grid container spacing={2}>
                    <AuthorizedElement roles={[__CONTEXTO_VISUALIZAR]}>
                        <ButtonList />
                    </AuthorizedElement>
                    <AuthorizedElement roles={[__CONTEXTO_ADICIONAR]}>
                        <ButtonAdd
                            to={{
                                pathname: '/admin/contexto/adicionar',
                                state: {
                                    history: {
                                        path: `/admin/contexto`,
                                        state: this.state,
                                    }
                                }
                            }}
                            title="Adicionar Variável"
                        />
                    </AuthorizedElement>
                </Grid>

                {this.state.loading ? <Loading /> : this.fieldsContext()}

                {AuthorizedFunction([ __CONTEXTO_ORDENAR_IMPRESSAO, __CONTEXTO_CALCULAR_MATERIAIS ]) &&
                    <>
                        <Divider variant="fullWidth" />

                        <Title>Ações de Impressão de Materiais para Escolas</Title>

                        <Grid container spacing={2} style={{ marginTop: '16px' }}>
                            <AuthorizedElement roles={[__CONTEXTO_ORDENAR_IMPRESSAO]}>
                                <Grid item sm={12} lg={3}>
                                    <ButtonPrimary
                                        startIcon={this.state.loadingButtonOrder ? <CircularProgress size={16} /> : <SwapVertOutlinedIcon />}
                                        onClick={this.handleOrderSchools}
                                        name="Ordenar Para Impressão"
                                        disabled={this.state.ordenacaoJaRealizada || this.state.loadingButtonOrder}
                                    />
                                </Grid>
                            </AuthorizedElement>
                            <AuthorizedElement roles={[__CONTEXTO_CALCULAR_MATERIAIS]}>
                                <Grid item sm={12} lg={3}>
                                    <ButtonPrimary
                                        startIcon={this.state.loadingButtonCalc ? <CircularProgress size={16} /> : <FunctionsIcon />}
                                        onClick={this.handleCalcDocsSchools}
                                        name="Calcular Materiais Para Impressão"
                                        disabled={this.state.loadingButtonCalc}
                                    />
                                </Grid>
                            </AuthorizedElement>
                        </Grid>
                    </>
                }
            </Layout >
        )
    }
}

export default Contexto;