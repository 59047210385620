import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import ObjetoPostalService from '../../services/ObjetoPostal';

import Filters from '../../components/Filters/Filters';
import Text from '../../components/Inputs/Text/Text';
import { DatePicker } from '../../components/Inputs/DatePicker/DatePicker';
import Select from '../../components/Inputs/Select/Select';

import EscolaFilterFields from '../Escola/EscolaFilterFields';
import EnderecoFilter from '../Endereco/EnderecoFilter';

import { toDate, parseDate } from '../../helper/DateHelper';
import { download } from '../../helper/FileHelper';
import { removePagingAndSorting } from '../../helper/PaginationHelper';

export class ObjetoPostalFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
                numero: '',
                dataEnvio: null,
                status: '',
            },
            exportPlan: false,
            count: 0,
            optionsStatus: [],
        }
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            filters: { ...this.state.filters, [e.target.name]: e.target.value }
        });
    };

    // INPUTS DATE PICKER
    handleChangeDate = (e, name) => {
        this.setState({
            filters: { ...this.state.filters, [name]: e },
        });
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES TIPO FORM
    setEscolaFilterFields = form => this.EscolaFilterFields = form;
    setEnderecoFilter = form => this.EnderecoFilter = form;

    // APLICAR PERSISTENCIA
    handleFilterPersistence = (filters) => {
        filters = removePagingAndSorting(filters);

        this.setState({
            filters: {
                numero: filters.find(f => f.field === 'numero') ? filters.find(f => f.field === 'numero').value : "",
                dataEnvio: filters.find(f => f.field === 'dataEnvio') ? parseDate(filters.find(f => f.field === 'dataEnvio').value) : null,
                status: filters.find(f => f.field === 'status') ? filters.find(f => f.field === 'status').value : null,
            },
            count: filters.reduce((total, f) => f.value !== '' ? total + 1 : total, 0),
        })

        this.EscolaFilterFields.setState({
            filters: {
                inepSchool: filters.find(f => f.field === 'codigoMec') ? filters.find(f => f.field === 'codigoMec').value : "",
            }
        })

        this.EnderecoFilter.setState({
            filters: {
                UF: filters.find(f => f.field === 'uf') ? filters.find(f => f.field === 'uf').value : "",
                city: filters.find(f => f.field === 'municipio') ? filters.find(f => f.field === 'municipio').value : "",
                region: filters.find(f => f.field === 'regiao') ? filters.find(f => f.field === 'regiao').value : "",
            }
        })
    }

    // FILTROS
    handleFilterApply = () => {
        const f = [];
        const { filters, exportPlan } = this.state;
        const filtersEscola = this.EscolaFilterFields.state.filters;
        const filtersEndereco = this.EnderecoFilter.state.filters;

        if (filters.numero) f.push({ field: 'numero', value: filters.numero })
        if (filters.dataEnvio) f.push({ field: 'dataEnvio', value: toDate(filters.dataEnvio) })
        if (filters.status) f.push({ field: 'status', value: filters.status })

        if (filtersEscola.inepSchool) f.push({ field: 'codigoMec', value: filtersEscola.inepSchool })

        if (filtersEndereco.UF) f.push({ field: 'uf', value: filtersEndereco.UF })
        if (filtersEndereco.city) f.push({ field: 'municipio', value: filtersEndereco.city })
        if (filtersEndereco.region) f.push({ field: 'regiao', value: filtersEndereco.region })

        if (exportPlan) {
            f.push({ field: 'exportarPlanilha', value: exportPlan });

            ObjetoPostalService.export(f)
                .then(res => download(res))
                .finally(() => this.setState({ exportPlan: false }));
        } else {
            this.props.handleFilterChange(f);
        };
    }

    // LIMPAR FILTROS
    handleFilterClear = () => {
        this.setState({
            filters: {
                numero: '',
                dataEnvio: null,
                status: '',
            },
            exportPlan: false,
            count: 0,
        })

        this.EscolaFilterFields.setState({
            filters: {
                inepSchool: '',
            }
        })

        this.EnderecoFilter.setState({
            filters: {
                city: '',
                UF: '',
                region: '',
            }
        })

        this.props.handleFilterChange([], false);
    }

    // CONTAGEM DE FILTROS ATIVOS
    handleCountFilters = () => this.state.count;

    componentDidMount() {
        ObjetoPostalService.listStatus()
            .then(res => this.setState({ ...this.state, optionsStatus: [...res.data] }) );
    }

    render() {
        return (
            <Filters
                handleFilter={this.handleFilterApply}
                handleFilterClear={this.handleFilterClear}
                countFilters={this.handleCountFilters}
            >
                <Grid item container spacing={4}>
                    <Grid item sm={12} lg={3}>
                        <Text
                            label="Código Sedex"
                            name='numero'
                            value={this.state.filters.numero}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <EscolaFilterFields
                        parentRef={this.setEscolaFilterFields}
                        disabledEmail={true}
                        disabledNameSchool={true}
                    />
                    <Grid item sm={12} lg={2}>
                        <DatePicker
                            clearable
                            disableFuture
                            label="Data de Envio"
                            name='dataEnvio'
                            value={this.state.filters.dataEnvio}
                            onChange={(e) => this.handleChangeDate(e, 'dataEnvio')}
                        />
                    </Grid>
                    <Grid item sm={12} lg={4}>
                        <Select
                            label="Status"
                            name='status'
                            value={this.state.filters.status}
                            options={this.state.optionsStatus}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={4}>
                    <EnderecoFilter
                        parentRef={this.setEnderecoFilter}
                        withRegion={true}
                    />
                </Grid>
            </Filters>
        )
    }
}

export default ObjetoPostalFilter;