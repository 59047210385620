import React, { Component } from 'react';

import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { Chip, Divider, Grid } from '@material-ui/core';
import { Typography } from "@material-ui/core"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import Dialog from '../../components/Dialog/Dialog';
import { ButtonDefault } from '../../components/Button/Button';
import Arquivo from '../../components/Inputs/File/File';
import Toast from "../../components/Toast/Toast";
import SelectOption from '../../components/Inputs/Select/Select';
import Text from '../../components/Inputs/Text/Text';

import { ButtonSave } from '../../forms/Buttons/ButtonsForm';

import { isBlank, isBlankHelperText } from '../../helper/ValidationHelper';
import { download } from '../../helper/FileHelper';

import AlunoService from '../../services/Aluno';
import { errors } from '../../services/API';

import AuthorizedElement from '../../security/AuthorizedElement';
import AuthorizedFunction from '../../security/AuthorizedFunction';
import {
    __ALUNO_TERMO_LISTAR,
    __ALUNO_TERMO_VALIDAR,
    __ALUNO_TERMO_DOWNLOAD,
    __ADMINISTRADOR,
    __CENTRAL,
} from '../../security/RoleConfiguration';

import { optionsTermo, patternTermo } from '../../variables/Enums/Aluno';

export class TermoAutorizacao extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            dialog: {
                open: false,
                aluno: null,
                nome: '',
                loading: false,
            },
            termos: [],
            jaPossuiTermoValido: false,
            termo: {
                cdTermo: null,
                status: null,
                justificativa: null,
            },
            errors: {
                justificativa: false,
            },
            helpers: {
                justificativa: null,
            },
            dialogJustify: {
                open: false,
                loading: false,
            },
            openAccordion: true,
        }
    }

    setToast = t => this.Toast = t;
    setArquivo = f => this.Arquivo = f;

    handleChange = (e) => {
        this.setState({
            termo: {
                ...this.state.termo,
                [e.target.name]: e.target.value,
            },
            errors: { ...this.state.errors, [e.target.name]: false },
            helpers: { ...this.state.helpers, [e.target.name]: null },
        });
    }

    // AÇÕES MODAL
    actions = () => {
        return (
            <>
                <ButtonDefault
                    onClick={this.close}
                    startIcon={<ClearOutlinedIcon />}
                    style={{ margin: 4 }}
                    name="Fechar"
                />
            </>
        )
    }

    // FECHAR MODAL
    close = () => {
        this.props.handleClose();
        this.setState({
            dialog: {
                open: false,
                aluno: null,
                nome: '',
                loading: false,
            },
            termos: [],
            openAccordion: true,
        }, () => this.closeJustify())
    }

    // AÇÕES MODAL JUSTIFICATIVA
    actionsJustify = () => {
        return (
            <>
                <ButtonSave
                    onClick={this.handleChangeStatusSubmit}
                    name="Validar Termo"
                />
                <ButtonDefault
                    onClick={this.closeJustify}
                    startIcon={<ClearOutlinedIcon />}
                    style={{ margin: 4 }}
                    name="Cancelar"
                />
            </>
        )
    }

    // FECHAR MODAL JUSTIFICATIVA
    closeJustify = () => {
        this.setState({
            termo: {
                cdTermo: null,
                status: null,
                justificativa: null,
            },
            errors: {
                justificativa: false,
            },
            helpers: {
                justificativa: null,
            },
            dialogJustify: {
                open: false,
                loading: false,
            }
        })
    }

    // SALVAR TERMO
    handleSubmit = () => {
        this.setState({ dialog: { ...this.state.dialog, loading: true } });

        const formArquivoIsValid = this.Arquivo.isValid();

        if (!formArquivoIsValid) {
            this.setState({ dialog: { ...this.state.dialog, loading: false } });
            return
        }

        const config = { headers: { 'content-type': 'multipart/form-data' } }

        const file = new FormData();
        file.append('termo', this.Arquivo.state.file)

        AlunoService.enviarTermo(this.state.dialog.aluno, file, config)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Termo de Autorizacao Enviado com Sucesso. Em breve estará sendo analisado pela equipe da OBMEP.",
                        type: "success",
                        open: true
                    }
                });

                this.setState({
                    termos: [...this.state.termos, res.data],
                })

                this.Arquivo.setState({
                    file: null,
                })
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ dialog: { ...this.state.dialog, loading: false } }));
    }

    openModal(alunoId, nomeAluno) {
        this.setState({
            dialog: {
                ...this.state.dialog,
                open: true,
                aluno: alunoId,
                nome: nomeAluno,
            },
        }, () => this.buscarTermos());
    }

    buscarTermos = () => {
        if (this.state.dialog.aluno) {
            if (AuthorizedFunction([__ALUNO_TERMO_LISTAR])) {
                AlunoService.buscarTermos(this.state.dialog.aluno)
                    .then(res => {
                        if (res.data.length > 0) {
                            this.setState({
                                termos: [...res.data],
                            })
                        }
                    })
                    .catch(() => {
                        this.Toast.setState({
                            message: {
                                message: "Não Foi Possível buscar os Termos de Autorização dentro do Sistema.",
                                type: 'error',
                                open: true
                            }
                        })
                    })
            }

            AlunoService.verificarSePossuiTermoValido(this.state.dialog.aluno)
                .then(res => this.setState({ jaPossuiTermoValido: res.data }));
        }
    }

    downloadTermo = (cdTermo) => {
        this.Toast.setState({
            message: {
                message: "Em Instantes o Download Iniciará Automáticamente",
                type: "info",
                open: true
            }
        });

        AlunoService.downloadTermo(this.state.dialog.aluno, cdTermo)
            .then(res => download(res))
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            });
    }

    buttonDownloadTermo = (cdTermo) =>
        <Tooltip title="Download do Termo" TransitionComponent={Zoom}>
            <IconButton size="medium" component="div" onClick={() => this.downloadTermo(cdTermo)} disabled={!AuthorizedFunction([__ALUNO_TERMO_DOWNLOAD])} >
                <GetAppOutlinedIcon />
            </IconButton>
        </Tooltip>

    handleChangeStatus = (cdTermo, e) => {
        this.setState({
            termo: {
                ...this.state.termo,
                cdTermo: cdTermo,
                status: e.target.value,
            }
        }, () => this.handleChangeStatusAndVerifyJustify());
    }

    handleChangeStatusAndVerifyJustify = () => {
        if (this.state.termo.status === "INVALIDO")
            this.setState({ dialogJustify: { ...this.state.dialogJustify, open: true, } })
        else
            this.handleChangeStatusSubmit();
    }

    handleChangeStatusSubmit = () => {
        this.setState({
            dialogJustify: {
                ...this.state.dialogJustify,
                loading: true,
            }
        })

        const statusPrecisaDeJustificativa = this.state.termo.status === "INVALIDO";

        if (statusPrecisaDeJustificativa && !this.justifyIsValid()) {
            this.setState({
                dialogJustify: {
                    ...this.state.dialogJustify,
                    loading: false,
                }
            })
            return
        }

        const termo = {
            status: this.state.termo.status,
            justificativa: statusPrecisaDeJustificativa ? this.state.termo.justificativa : null,
        }

        AlunoService.atualizarStatusTermo(this.state.dialog.aluno, this.state.termo.cdTermo, termo)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Termo de Autorização atualizado com Sucesso.",
                        type: 'success',
                        open: true
                    }
                })

                this.buscarTermos(this.state.dialog.aluno);
            })
            .catch(() => {
                this.Toast.setState({
                    message: {
                        message: "Não Foi Possível Atualizar o Status do Termo de Autorização.",
                        type: 'error',
                        open: true
                    }
                })
            })
            .finally(() => this.closeJustify())
    }

    justifyIsValid = () => {
        if (isBlank(this.state.termo.justificativa)) {
            this.setState({
                errors: {
                    justificativa: isBlank(this.state.termo.justificativa) ? true : false,
                },
                helpers: {
                    justificativa: isBlank(this.state.termo.justificativa) ? isBlankHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    tableTermos = () => {
        return (
            <Accordion expanded={this.state.openAccordion} onChange={() => this.setState({ openAccordion: !this.state.openAccordion })}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                    <Typography variant="h6">Termos Anexados para Validação</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>#</b></TableCell>
                                <TableCell><b>Data de Upload</b></TableCell>
                                <TableCell><b>Status</b></TableCell>
                                {AuthorizedFunction([__ADMINISTRADOR, __CENTRAL]) &&
                                    <>
                                        <TableCell><b>Data de Validação</b></TableCell>
                                        <AuthorizedElement roles={[__ALUNO_TERMO_VALIDAR]}>
                                            <TableCell><b>Usuário que Validou</b></TableCell>
                                        </AuthorizedElement>
                                        <TableCell><b>Justificativa de Validação</b></TableCell>
                                    </>
                                }
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.termos.length > 0 ?
                                this.state.termos.map((termo) =>
                                    <TableRow key={termo.cdTermo} hover>
                                        <TableCell>{termo.cdTermo}</TableCell>
                                        <TableCell>{termo.dataUploadTermo}</TableCell>
                                        <TableCell width="25%">
                                            {AuthorizedFunction([__ALUNO_TERMO_VALIDAR]) && termo.status !== "VALIDO" && !this.state.jaPossuiTermoValido ?
                                                <SelectOption
                                                    hiddenblank
                                                    label="Status de Avaliação"
                                                    name={`status_${termo.cdTermo}`}
                                                    value={this.state.termos.find(t => t.cdTermo === termo.cdTermo).status}
                                                    onChange={(e) => this.handleChangeStatus(termo.cdTermo, e)}
                                                    options={optionsTermo}
                                                />
                                                :
                                                <Chip
                                                    size='small'
                                                    style={{
                                                        fontWeight: '400',
                                                        color: '#fff',
                                                        backgroundColor: termo.status === "VALIDO" ? "#026314" : termo.status === "INVALIDO" ? "#85040d" : "#1665cc",
                                                    }}
                                                    label={patternTermo(termo.status)}
                                                />
                                            }
                                        </TableCell>
                                        {AuthorizedFunction([__ADMINISTRADOR, __CENTRAL]) &&
                                            <>
                                                <TableCell>{termo.status === 'PENDENTE' ? "Não Tratado" : termo.dataValidacaoTermo}</TableCell>
                                                <AuthorizedElement roles={[__ALUNO_TERMO_VALIDAR]}>
                                                    <TableCell>{termo.status === 'PENDENTE' ? "Não Tratado" : termo.usuarioValidacao}</TableCell>
                                                </AuthorizedElement>
                                                <TableCell>{termo.status === 'PENDENTE' ? "Não Tratado" : termo.justificativaValidacao}</TableCell>
                                            </>
                                        }
                                        <TableCell>{this.buttonDownloadTermo(termo.cdTermo)}</TableCell>
                                    </TableRow>
                                )
                                :
                                <TableRow style={{ height: 53 }}>
                                    <TableCell colSpan={7} align="center">
                                        <Typography variant="body2"><b>Não foi enviado nenhum Termo de Autorização para este Aluno</b></Typography>
                                    </TableCell>
                                </TableRow>
                            }

                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
        )
    }

    render() {
        return (
            <>
                <Toast parentRef={this.setToast} />
                <Dialog
                    openDialog={this.state.dialog.open}
                    title={`Upload de Termo de Autorização - Aluno ${this.state.dialog.nome}`}
                    closeDialog={this.close}
                    actions={this.actions()}
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    {!this.state.jaPossuiTermoValido &&
                        <Grid container spacing={3} style={{ marginBottom: '16px' }}>
                            <Grid item lg={12}>
                                <Typography variant="body2">
                                    - Aceitamos arquivos nos formatos <b>.JPG</b> ou <b>.PDF</b> e com o tamanho máximo de <b>10Mb</b>.<br />
                                    - O Termo de Autorização deverá ser anexado usando o botão abaixo <b>"SELECIONAR ARQUIVO"</b>, e logo após submetido através do botão <b>"ENVIAR TERMO DE AUTORIZAÇÃO"</b>.<br />
                                    - O Termo de Autorização será encaminhado para a Central da OBMEP e será avaliado nos próximos dias.<br />
                                    - Você poderá anexar quantos Termos de Autorização forem necessários, caso haja a <b>NÃO</b> validação por parte da Central da OBMEP nos Termos já enviados.<br />
                                    - A partir de um Termo de Autorização validado, <b>NÃO</b> serão mais permitidos novos upload de outros Termos.<br />
                                </Typography>
                            </Grid>
                            <Grid item lg={9}>
                                <Arquivo parentRef={this.setArquivo} />
                            </Grid>
                            <Grid item lg={3}>
                                <ButtonSave
                                    name={`Enviar Termo de Autorização`}
                                    onClick={this.handleSubmit}
                                    loading={this.state.dialog.loading}
                                />
                            </Grid>
                        </Grid>
                    }

                    <AuthorizedElement roles={[__ALUNO_TERMO_LISTAR]}>
                        {!this.state.jaPossuiTermoValido &&
                            <Divider variant="fullWidth" style={{ marginBottom: '16px' }} />
                        }

                        <Grid container spacing={3}>
                            <Grid item lg={12}>
                                {this.tableTermos()}
                            </Grid>
                        </Grid>
                    </AuthorizedElement>
                </Dialog>

                <Dialog
                    openDialog={this.state.dialogJustify.open}
                    title={`Adicionar Justificativa`}
                    closeDialog={this.closeJustify}
                    actions={this.actionsJustify()}
                    fullWidth={true}
                    maxWidth={"sm"}
                >
                    <Grid container spacing={3}>
                        <Grid item sm={12} lg={12}>
                            <Text
                                required
                                label="Justificativa"
                                name="justificativa"
                                value={this.state.termo.justificativa}
                                error={this.state.errors.justificativa}
                                onChange={this.handleChange}
                                helperText={this.state.helpers.justificativa}
                            />
                        </Grid>
                    </Grid>
                </Dialog>
            </>
        )
    }
}

export default TermoAutorizacao;