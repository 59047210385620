import React, { Component } from 'react';

import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '../../components/Dialog/Dialog';
import { ButtonPrimary, ButtonDefault } from '../../components/Button/Button';

export class DialogConfirm extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            dialog: {
                open: false,
                title: null,
                text: null,
                id: null,
                loading: false,
                showConfirm: true,
            },  
        }
    }

    // LOADING AO SUBMETER MODAL
    loading = () => {
        this.setState({
            dialog: {
                loading: true,
                ...this.state.dialog
            }
        });
    }

    // AÇÕES MODAL
    actions = () => {
        return (
            <>
                {this.state.dialog.showConfirm &&
                    <ButtonPrimary
                        onClick={this.props.handleConfirm ? () => this.props.handleConfirm(this.state.dialog.id) : this.close}
                        startIcon={this.state.dialog.loading ? <CircularProgress size={16} /> : <CheckOutlinedIcon />}
                        style={{ margin: 4 }}
                        name="Confirmar"
                    />
                }
                <ButtonDefault 
                    onClick={this.props.handleCancel ?? this.close}
                    startIcon={<ClearOutlinedIcon />}
                    style={{ margin: 4 }}
                    name="Cancelar"
                />
            </>
        )
    }

    // FECHAR MODAL
    close = () => {
        this.setState({ 
            dialog: { 
                open: false, 
                title: null,
                text: null,
                id: null,
                loading: false,
            }
        })
    }

    render() {
        return (
            <>
                <Dialog
                    openDialog={this.state.dialog.open}
                    title={this.state.dialog.title}
                    closeDialog={this.close}
                    actions={this.actions()}
                    fullWidth={true}
                    maxWidth={"md"}
                >
                    {this.state.dialog.text}
                </Dialog>
            </>
        )
    }
}

export default DialogConfirm;