import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'; 

import ArquivoService from '../../services/Arquivo';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';

import { ButtonAdd } from '../../forms/Buttons/ButtonsAdd';
import Dialog from '../../forms/Dialog/DialogRemove';

import { sizeFiles, download } from '../../helper/FileHelper';

import AuthorizedElement from '../../security/AuthorizedElement';
import AuthorizedFunction from '../../security/AuthorizedFunction';
import {
  __ARQUIVO_EDITAR,
  __ARQUIVO_DOWNLOAD,
  __ARQUIVO_REMOVER,
  __ARQUIVO_ADICIONAR,
} from '../../security/RoleConfiguration';

import { Layout } from '../../layouts/private/Private';

import { patternFile, patternLocalExibicao } from '../../variables/Enums/Arquivo';

export class Arquivo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      loading: true,
    }
  }

  // FECHAR TOAST
  setToast = t => this.Toast = t;
  setDialog = t => this.Dialog = t;

  // LABELS
  labelEditar = id => !this.disabledEditar(id) ? "Editar" : "Você não tem permissão para fazer isso";
  labelDownload = id => !this.disabledDownload(id) ? "Realizar Download" : "Você não tem permissão para fazer isso";
  labelApagar = id => !this.disabledApagar(id) ? "Apagar" : "Você não tem permissão para fazer isso";

  // DISABLES
  disabledEditar = id => !AuthorizedFunction([__ARQUIVO_EDITAR]);
  disabledDownload = id => !AuthorizedFunction([__ARQUIVO_DOWNLOAD]);
  disabledApagar = id => !AuthorizedFunction([__ARQUIVO_REMOVER]);

  // ABRIR MODAL DE CONFIRMAÇÃO DE REMOÇÃO
  openDialogRemove = id => {
    const file = this.state.files.find(file => file.id === id);
    this.Dialog.setState({ 
      dialog: { 
        open: true, 
        title: `Deseja Remover ${file.tipo === "Prova" || file.tipo === "Máscara" ? `a ${file.tipo}` : `o ${file.tipo}`} ?`,
        text: `O Arquivo não poderá ser recuperado depois de ser removido. Após a exclusão será possivel adicionar um outro arquivo da mesmo tipo no sistema.`,
        id: id,
        loading: false,
      }
    });
  }

  handleEditClick = (id) => {
    this.props.history.push(`/admin/arquivo/${id}/editar`);
  }

  // REMOÇÃO
  handleRemoveClick = (id) => {
    this.Dialog.loading();

    ArquivoService.remove(id)
      .then(res => {
        const newFiles = this.state.files.filter(file => file.id !== id)

        this.setState({ 
          files: [...newFiles],
        });

        this.Toast.setState({
          message: {
            message: "O Arquivo foi Apagado com Sucesso.",
            type: 'success',
            open: true
          }
        })
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => this.Dialog.close());
  }

  // DOWNLOAD DO ARQUIVO
  handleDownloadClick = (id) => {
    this.Toast.setState({
      message: {
        message: "Em Instantes seu Download Iniciará Automáticamente",
        type: "info",
        open: true
      }
    });

    ArquivoService.download(id)
      .then(res => download(res))
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      });
  }

  loadData = () => {
    this.setState({ loading: true, });

    ArquivoService.list()
      .then(res => {
        if (res.data.content) {
          this.setState({ files: [...res.data.content], loading: false, });
        } else {
          this.setState({ files: [], loading: false, });
        }
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      })
  }

  // COMPONENT DID MOUNT
  componentDidMount() { this.loadData(); }

  render () {
    const pageName = 'Arquivos Para Download';
    const links = [];

    const columns = [
      { label: '#', name: 'id', func: null, key: true },
      { label: 'Nome do Arquivo', name: 'nomeArquivo', func: null, key: false },
      { label: 'Tamanho', name: 'tamanho', func: sizeFiles, key: false },
      { label: 'Local de Exibição', name: 'localExibicao', func: patternLocalExibicao, key: false },
      { label: 'Data Inicio de Download', name: 'dataInicioDownload', func: null, key: false },
      { label: 'Data Fim de Download', name: 'dataFimDownload', func: null, key: false },
      { label: 'Tipo', name: 'tipo', func: patternFile, key: false },
      { label: 'Descrição', name: 'descricao', func: null, key: false },
      { label: 'Usuário', name: 'usuario', func: null, key: false },
      { label: 'Data de Upload', name: 'dataCriacao', func: null, key: false }
    ];
    
    const actions = [
      {
        name: this.labelEditar,
        func: this.handleEditClick,
        icon: <EditOutlinedIcon />,
        disabled: this.disabledEditar,
      },
      { 
        name: this.labelDownload, 
        func: this.handleDownloadClick, 
        icon: <GetAppIcon />, 
        disabled: this.disabledDownload,
      },
      { 
        name: this.labelApagar, 
        func: this.openDialogRemove,
        icon: <DeleteOutlinedIcon />, 
        disabled: this.disabledApagar,
        color: "secondary",
      },
    ];

    return (
      <Layout>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AuthorizedElement roles={[__ARQUIVO_ADICIONAR]}>
              <Grid container spacing={3}>
                <ButtonAdd 
                  to='/admin/arquivo/adicionar'
                  title="Adicionar Arquivo"
                />
              </Grid>
            </AuthorizedElement>
          
            <Table              
              columns={columns}
              tableData={this.state.files}
              actions={actions}
              page={null}
              sort={null}
              rowsPerPage={null}
              loading={this.state.loading}
            />
          </Grid>
        </Grid>

        <Dialog 
          parentRef={this.setDialog}
          handleConfirm={this.handleRemoveClick}
        />
      </Layout>
    )
  }
}

export default withRouter(Arquivo);