import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterPeriodoRegistroParticipacaoCadastroAlunos } from '../../helper/ContextHelper';

import InscricaoService from '../../services/Inscricao';
import EscolaService from '../../services/Escola';
import { errors } from '../../services/API';

import Fase1Participantes from '../../forms/Inscricao/Fase1Participantes';
import { ButtonCancel, ButtonSave } from '../../forms/Buttons/ButtonsForm';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';

import { verifyPersistence } from '../../helper/PaginationHelper';
import { locationEscolaParse } from '../../helper/ParseStateHelper';

import { Layout } from '../../layouts/private/Private';
import Aluno from '../Aluno/List';
import { Divider } from '@material-ui/core';

export class Fase2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cdEscola: null,
      escola: null,
      loading: true,
      loadingButtonSave: false,
      history: {
        path: null,
        state: null,
      }
    }

    this.state = verifyPersistence(this.state, this.props.location);
  }

  // FECHAR TOAST
  setToast = t => this.Toast = t;
  setFase1Participantes = t => this.Fase1Participantes = t;

  loadData = () => {
    const f = locationEscolaParse(this.props.location);
    const cdEscola = !isNaN(this.props.match.params.id) ? this.props.match.params.id : f.find(f => f.field === 'id').value;

    this.setState({ loading: true });

    EscolaService.find(cdEscola)
      .then(res => {
        const { inscricao } = res.data

        this.Fase1Participantes.setState({
          inscricaoMirim1QtdAlunosPrevistos: inscricao.mirim1.qtdAlunosPrevistos,
          inscricaoMirim2QtdAlunosPrevistos: inscricao.mirim2.qtdAlunosPrevistos,
          inscricaoMirim1QtdAlunosParticipantes: inscricao.mirim1.qtdAlunosParticipantes,
          inscricaoMirim2QtdAlunosParticipantes: inscricao.mirim2.qtdAlunosParticipantes,
        })

        this.setState({
          escola: res.data,
          cdEscola: cdEscola,
          loading: false,
        });
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      })
  }

  handleBack = () => {
    if (this.state.history && this.state.history.path) {
      this.props.history.push({
        pathname: `${this.state.history.path}`,
        state: {
          history: {
            state: this.state.history.state
          }
        }
      });
    } else {
      this.props.history.goBack();
    }
  }

  handleSubmit = () => {
    this.setState({ loadingButtonSave: true });

    const formValid = this.Fase1Participantes.isValid();

    if (!formValid) {
      this.setState({ loadingButtonSave: false });
      return
    }

    const inscricao = {
      mirim1: this.Fase1Participantes.state.inscricaoMirim1QtdAlunosParticipantes,
      mirim2: this.Fase1Participantes.state.inscricaoMirim2QtdAlunosParticipantes,
    }

    InscricaoService.informarParticipantes(this.state.cdEscola, inscricao)
      .then(res => {
        this.Toast.setState({
          message: {
            message: `Quantitativos Salvos com Sucesso`,
            type: "success",
            open: true
          }
        })
      })
      .catch(err => {
        this.Toast.setState({
          message: {
            message: `Ocorreu um Problema ao Salvar Quantitativos`,
            type: "error",
            open: true
          }
        })
      })
      .finally(() => this.setState({ loadingButtonSave: false }))
  }

  componentDidMount() { this.loadData(); }

  render() {
    const pageName = 'Informar Quantidade de Participantes';
    const links = [];
    const contexts = this.props.contexts;

    return (
      <Layout>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid container spacing={3} style={{ marginBottom: 16 }}>
          <Grid item xs={12}>
            <Fase1Participantes disabled={isAfterPeriodoRegistroParticipacaoCadastroAlunos(contexts)} parentRef={this.setFase1Participantes} />

            <Grid container spacing={3} style={{ marginTop: 16 }}>
              <Grid item sm={12} lg={12}>
                <ButtonSave
                  onClick={this.handleSubmit}
                  loading={this.state.loadingButtonSave}
                  name={"Salvar Quantidade de Alunos Participantes"}
                  saveDisabled={isAfterPeriodoRegistroParticipacaoCadastroAlunos(contexts)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider variant="fullWidth" />

        {this.state.escola &&
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Aluno 
                withoutLayout 
                school={this.state.escola} 
              />
            </Grid>
          </Grid>
        }

        <Grid container spacing={3} alignItems='flex-end' style={{ marginTop: 16 }}>
          <Grid item sm={12} lg={12}>
            <ButtonCancel
              onBack={this.handleBack}
              nameOnBack={"Voltar"}
            />
          </Grid>
        </Grid>
      </Layout >
    )
  }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Fase2));