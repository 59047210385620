import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import {
  isPeriodoInscricoes,
  isPeriodoRegistroParticipacaoCadastroAlunos,
  isAfterInicioPeriodoRegistroParticipacaoCadastroAlunos,
  isAfterInicioPeriodoInserirNotaFase2,
  isPeriodoInserirNotaFase2,
} from '../../helper/ContextHelper';
import { locationEscolaParse } from '../../helper/ParseStateHelper';
import { download } from '../../helper/FileHelper';

import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SchoolIcon from '@material-ui/icons/School';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import Divider from '@material-ui/core/Divider';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import { ButtonPrimary } from "../../components/Button/Button";
import Boxer from '../../components/Boxer/Boxer';
import Loading from '../../components/Loading/Loading';

import DadosEscola from '../../forms/Recibo/DadosEscola';
import DadosResponsavel from '../../forms/Recibo/DadosResponsavel';
import DialogChangePassword from '../../forms/Dialog/DialogChangePassword'

import { makeStyles } from '@material-ui/core/styles';

import AuthorizedElement from '../../security/AuthorizedElement';
import AuthorizedFunction from '../../security/AuthorizedFunction';
import {
  __DASHBOARD_DADOS_EDITAR,
  __DASHBOARD_RECIBO,
  __DASHBOARD_SENHA,
  __DASHBOARD_INSCRICAO_EDITAR,
  __DASHBOARD_INSCRICAO_ALUNOS_CLASSIFICADOS,
  __DASHBOARD_DOWNLOAD,
} from '../../security/RoleConfiguration';

import ArquivoService from '../../services/Arquivo';
import DashboardService from '../../services/Dashboard';
import { errors } from '../../services/API';

import { Layout } from '../../layouts/private/Private';

export const useStyles = makeStyles(theme => ({
  body: {
    margin: theme.spacing(3, 0),
  },
}));

const ButtonChangePassword = ({ openDialog }) => {
  return (
    <Grid item >
      <ButtonPrimary
        startIcon={<EditIcon />}
        onClick={openDialog}
        name="Alterar Senha de Acesso"
      />
    </Grid>
  )
}

const ButtonEdit = ({ id }) => {
  return (
    <Grid item >
      <ButtonPrimary
        startIcon={<EditIcon />}
        to={{ pathname: `/admin/escola/${id}/responsavel/editar`, state: { escola: id } }}
        component={Link}
        name="Editar Dados do Responsável"
      />
    </Grid>
  )
}

const ButtonEditarInscricao = ({ id }) => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={4} className={classes.body}>
      <ButtonPrimary
        startIcon={<SchoolIcon />}
        to={{ pathname: `/admin/inscricao/${id}/editar`, state: { escola: id } }}
        component={Link}
        name="Editar Inscrição"
      />
    </Grid>
  )
}

const ButtonRecibo = ({ id }) => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={2} className={classes.body}>
      <ButtonPrimary
        startIcon={<ReceiptIcon />}
        to={{ pathname: `/admin/inscricao/${id}/recibo`, state: { escola: id } }}
        component={Link}
        name="Recibo de Inscrição"
      />
    </Grid>
  )
}

const ButtonInformarAlunosClassificaveis = ({ id, contexts }) => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={4} className={classes.body}>
      <ButtonPrimary
        startIcon={<FormatListNumberedRtlIcon />}
        to={{ pathname: `/admin/inscricao/${id}/fase-2`, state: { escola: id } }}
        component={Link}
        name={`${isPeriodoRegistroParticipacaoCadastroAlunos(contexts) ? "Informar " : ""}Alunos Classificaveis e Quantidade de Alunos Participantes`}
      />
    </Grid>
  )
}

const ButtonInformarNotaFase2Alunos = ({ id, contexts }) => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={4} className={classes.body}>
      <ButtonPrimary
        startIcon={<FormatListNumberedRtlIcon />}
        to={{ pathname: `/admin/inscricao/${id}/fase-2`, state: { escola: id } }}
        component={Link}
        name={`${isPeriodoInserirNotaFase2(contexts) ? "Informar Notas dos " : ""}Alunos na Fase 2`}
      />
    </Grid>
  )
}

const ButtonDownload = ({ label, handleDownloadClick }) => {
  const classes = useStyles();
  return (
    <Grid item sm={12} lg={4} className={classes.body}>
      <ButtonPrimary
        onClick={handleDownloadClick}
        name={label}
      />
    </Grid>
  )
}

export class Home extends Component {
  constructor() {
    super()
    this.state = {
      arquivos: [],
      responsavel: {
        email: '',
        cpf: '',
        nome: '',
        telefones: '',
        cargo: '',
      },
      escola: {
        cdEscola: '',
        dependenciaAdministrativa: '',
        nome: '',
        uf: '',
        municipio: '',
        telefones: '',
        email: '',
        downloadFase2: false,
        inscricao: null,
        downloadCertificado: false,
      },
      loading: true,
    }
  }

  // DECLARAÇÕES DE REFERENCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setDialogChangePassword = p => this.DialogChangePassword = p;

  // DOWNLOAD DE PROVA E GABARITO
  handleDownloadClick = (id) => {
    this.Toast.setState({
      message: {
        message: "Em Instantes o Download Iniciará Automáticamente",
        type: "info",
        open: true
      }
    });

    ArquivoService.download(id)
      .then(res => download(res))
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      });
  }

  // OPEN DIALOG DE TROCA DE SENHA
  handleOpenDialogChangePassword = () => this.DialogChangePassword.setState({ open: true, id: this.state.escola.cdEscola });

  async componentDidMount() {
    this.props.loadContext();

    const f = locationEscolaParse(this.props.location);

    await DashboardService.init(f)
      .then(res => {
        this.setState({
          arquivos: [...res.data.arquivos],
          responsavel: {
            email: res.data.escola.responsavel.nmEmail,
            cpf: res.data.escola.responsavel.nmCpf,
            nome: res.data.escola.responsavel.nmNome,
            telefones: res.data.escola.responsavel.nmTelefonesFormatados,
            cargo: res.data.escola.responsavel.nmCargo,
          },
          escola: {
            cdEscola: res.data.escola.cdMecEscola,
            dependenciaAdministrativa: res.data.escola.nmDependenciaAdministrativa,
            nome: res.data.escola.nmEscola,
            uf: res.data.escola.endereco.municipio.uf.nmUf,
            municipio: res.data.escola.endereco.municipio.nmMunicipio,
            telefones: res.data.escola.nmTelefonesFormatados,
            email: res.data.escola.nmEmail,
            downloadFase2: res.data.escola.downloadFase2,
            inscricao: res.data.escola.inscricao,
            downloadCertificado: res.data.escola.downloadCertificado,
          },
        })
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => this.setState({ loading: false }));
  }

  sizeBoxer = () => (!isPeriodoInscricoes(this.props.contexts) && AuthorizedFunction([__DASHBOARD_DADOS_EDITAR])) || AuthorizedFunction([__DASHBOARD_SENHA]) ? 5 : 6;

  verificaHabilitarPorQuantidadeAlunos = (habilitarSePossuiAlunos, qtdAlunos) => habilitarSePossuiAlunos ? qtdAlunos > 0 : false;

  verificaSeArquivoDeveAparecerNoBloco = (arquivo, tipo) => arquivo.localExibicao === tipo && arquivo.downloadAberto 
      && (this.verificaHabilitarPorQuantidadeAlunos(arquivo.exibirParaAlunosMirim1, this.state.escola.inscricao.mirim1.qtdAlunosPrevistos) 
        || this.verificaHabilitarPorQuantidadeAlunos(arquivo.exibirParaAlunosMirim2, this.state.escola.inscricao.mirim2.qtdAlunosPrevistos));

  verificarCasoSejaCertificadoSePodeSerExibido = (arquivo) => arquivo.tipo !== "CERTIFICADO" || (arquivo.tipo === "CERTIFICADO" && this.state.escola.downloadCertificado);

  render() {
    const page = 'Início';
    const links = [];
    const contexts = this.props.contexts;

    return (
      <Layout>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={page} />
          </Grid>
        </Grid>
        <Title>{page}</Title>

        <Grid container spacing={3}>
          {this.state.loading ?
            <Loading />
            :
            <>
              {this.state.escola.cdEscola &&
                <>
                  <Grid item sm={12} xs={this.sizeBoxer()} lg={this.sizeBoxer()} >
                    <DadosEscola escola={this.state.escola} />
                  </Grid>
                  <Grid item sm={12} xs={this.sizeBoxer()} lg={this.sizeBoxer()} >
                    <DadosResponsavel responsavel={this.state.responsavel} />
                  </Grid>

                  {((!isPeriodoInscricoes(contexts) && AuthorizedFunction([__DASHBOARD_DADOS_EDITAR])) || AuthorizedFunction([__DASHBOARD_SENHA])) &&
                    <Grid container item xs={2} lg={2} sm={12} justifycontent='flex-end' alignItems='center'>
                      {!isPeriodoInscricoes(contexts) && AuthorizedFunction([__DASHBOARD_DADOS_EDITAR]) &&
                        <ButtonEdit id={this.state.escola.cdEscola} />
                      }

                      <AuthorizedElement roles={[__DASHBOARD_SENHA]} >
                        <ButtonChangePassword openDialog={this.handleOpenDialogChangePassword} />
                        <DialogChangePassword parentRef={this.setDialogChangePassword} />
                      </AuthorizedElement>
                    </Grid>
                  }
                </>
              }

              {(((isPeriodoInscricoes(contexts) && this.state.escola.cdEscola && AuthorizedFunction([__DASHBOARD_INSCRICAO_EDITAR]))
                || (this.state.escola.cdEscola && AuthorizedFunction([__DASHBOARD_RECIBO])))
                || (AuthorizedFunction([__DASHBOARD_DOWNLOAD]) && this.state.arquivos.some(arquivo => this.verificaSeArquivoDeveAparecerNoBloco(arquivo, "INSCRICAO") && this.verificarCasoSejaCertificadoSePodeSerExibido(arquivo)))) &&
                <Grid item xs={12}>
                  <Boxer category="Inscrição">                      
                    {isPeriodoInscricoes(contexts) && this.state.escola.cdEscola &&
                      <AuthorizedElement roles={[__DASHBOARD_INSCRICAO_EDITAR]}>
                        <ButtonEditarInscricao id={this.state.escola.cdEscola} />
                      </AuthorizedElement>
                    }

                    {this.state.escola.cdEscola &&  
                      <AuthorizedElement roles={[__DASHBOARD_RECIBO]}>
                        <ButtonRecibo id={this.state.escola.cdEscola} />
                      </AuthorizedElement>
                    }

                    <AuthorizedElement roles={[__DASHBOARD_DOWNLOAD]}>
                      <Divider />

                      {this.state.arquivos.map(arquivo =>
                        <Fragment key={arquivo.id}>
                          {(this.verificaSeArquivoDeveAparecerNoBloco(arquivo, "INSCRICAO") && this.verificarCasoSejaCertificadoSePodeSerExibido(arquivo)) &&
                            <ButtonDownload label={arquivo.nomeArquivo} handleDownloadClick={() => this.handleDownloadClick(arquivo.id)} />
                          }
                        </Fragment>
                      )}
                    </AuthorizedElement>
                  </Boxer>
                </Grid>
              }

              {((isAfterInicioPeriodoRegistroParticipacaoCadastroAlunos(contexts) && this.state.escola.cdEscola && AuthorizedFunction([__DASHBOARD_INSCRICAO_ALUNOS_CLASSIFICADOS]))
                || (AuthorizedFunction([__DASHBOARD_DOWNLOAD]) && this.state.arquivos.some(arquivo => this.verificaSeArquivoDeveAparecerNoBloco(arquivo, "FASE_1") && this.verificarCasoSejaCertificadoSePodeSerExibido(arquivo)))) &&
                <Grid item xs={12}>
                  <Boxer category="1ª Fase">
                    {isAfterInicioPeriodoRegistroParticipacaoCadastroAlunos(contexts) && this.state.escola.cdEscola && AuthorizedFunction([__DASHBOARD_INSCRICAO_ALUNOS_CLASSIFICADOS]) &&
                      <ButtonInformarAlunosClassificaveis id={this.state.escola.cdEscola} contexts={contexts}/>
                    }

                    <AuthorizedElement roles={[__DASHBOARD_DOWNLOAD]}>
                      <Divider />

                      {this.state.arquivos.map(arquivo =>
                        <Fragment key={arquivo.id}>
                          {(this.verificaSeArquivoDeveAparecerNoBloco(arquivo, "FASE_1") && this.verificarCasoSejaCertificadoSePodeSerExibido(arquivo)) &&
                            <ButtonDownload label={arquivo.nomeArquivo} handleDownloadClick={() => this.handleDownloadClick(arquivo.id)} />
                          }
                        </Fragment>
                      )}
                    </AuthorizedElement>
                  </Boxer>
                </Grid>
              }

              {((isAfterInicioPeriodoInserirNotaFase2(contexts) && this.state.escola.cdEscola && AuthorizedFunction([__DASHBOARD_INSCRICAO_ALUNOS_CLASSIFICADOS]))
                || (this.state.escola.downloadFase2 && (AuthorizedFunction([__DASHBOARD_DOWNLOAD]) && this.state.arquivos.some(arquivo => this.verificaSeArquivoDeveAparecerNoBloco(arquivo, "FASE_2") && this.verificarCasoSejaCertificadoSePodeSerExibido(arquivo))))) && 
                <Grid item xs={12}>
                  <Boxer category="2ª Fase">
                    {isAfterInicioPeriodoInserirNotaFase2(contexts) && this.state.escola.cdEscola && AuthorizedFunction([__DASHBOARD_INSCRICAO_ALUNOS_CLASSIFICADOS]) &&
                      <ButtonInformarNotaFase2Alunos id={this.state.escola.cdEscola} contexts={contexts}/>
                    }

                    {this.state.escola.downloadFase2 && 
                      <AuthorizedElement roles={[__DASHBOARD_DOWNLOAD]}>
                        <Divider />

                        {this.state.arquivos.map(arquivo =>
                          <Fragment key={arquivo.id}>
                            {(this.verificaSeArquivoDeveAparecerNoBloco(arquivo, "FASE_2") && this.verificarCasoSejaCertificadoSePodeSerExibido(arquivo)) &&
                              <ButtonDownload label={arquivo.nomeArquivo} handleDownloadClick={() => this.handleDownloadClick(arquivo.id)} />
                            }
                          </Fragment>
                        )}
                      </AuthorizedElement>
                    }
                  </Boxer>
                </Grid>
              }

              {(AuthorizedFunction([__DASHBOARD_DOWNLOAD]) 
                  && this.state.arquivos.some(arquivo => this.verificaSeArquivoDeveAparecerNoBloco(arquivo, "RELATORIO") && this.verificarCasoSejaCertificadoSePodeSerExibido(arquivo))) &&
                <Grid item xs={12}>
                  <Boxer category="Relatórios">
                    <AuthorizedElement roles={[__DASHBOARD_DOWNLOAD]}>
                      <Divider />

                      {this.state.arquivos.map(arquivo =>
                        <Fragment key={arquivo.id}>
                          {(this.verificaSeArquivoDeveAparecerNoBloco(arquivo, "RELATORIO") && this.verificarCasoSejaCertificadoSePodeSerExibido(arquivo)) &&
                            <ButtonDownload label={arquivo.nomeArquivo} handleDownloadClick={() => this.handleDownloadClick(arquivo.id)} />
                          }
                        </Fragment>
                      )}
                    </AuthorizedElement>
                  </Boxer>
                </Grid>
              }
            </>
          }
        </Grid>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));