import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import Cache from './Cache';

import RelatorioService from '../../services/Relatorio';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Filters from '../../components/Filters/Filters';
import Loading from '../../components/Loading/Loading';

import DadosEscolas from '../../forms/Relatorio/DadosEscolas';
import EnderecoFilter from '../../forms/Endereco/EnderecoFilter';
import DependenciaAdministrativaFilter from '../../forms/DependenciaAdministrativa/DependenciaAdministrativaFilter';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __RELATORIO_ESCOLAS_EXPORTAR } from '../../security/RoleConfiguration';

import { download } from '../../helper/FileHelper';

import { Layout } from '../../layouts/private/Private';

export class Escolas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ultimaChamada: '',
      loading: true,
      schools: [],
    }
  }

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setDadosEscolas = d => this.DadosEscolas = d;
  setEnderecoFilter = f => this.EnderecoFilter = f;
  setDependenciaAdministrativaFilter = d => this.DependenciaAdministrativaFilter = d;

  // FILTROS
  ApplyFilters = () => {
    this.setState({ loading: true });

    const f = [];
    const filtersEndereco = this.EnderecoFilter.state.filters;
    const filtersDepAdm = this.DependenciaAdministrativaFilter.state.filters;

    if (filtersEndereco.UF) f.push({ field: 'cdUf', value: filtersEndereco.UF })
    if (filtersDepAdm.adminDep) f.push({ field: 'nmDependenciaAdministrativa', value: filtersDepAdm.adminDep })

    RelatorioService.filtersReportAllSchools(f)
      .then(res => {
        this.setState({ 
          ultimaChamada: res.data.ultimaChamada,
          loading: false,
          schools: [ ...res.data.cache ]
        });
      })
      .catch(error => {
        this.setState({ loading: false });
      })
  }

  // EXPORTAÇÃO
  handleExportChange = () => {
    const f = [];
    const filtersEndereco = this.EnderecoFilter.state.filters;
    const filtersDepAdm = this.DependenciaAdministrativaFilter.state.filters;

    if (filtersEndereco.UF) f.push({ field: 'cdUf', value: filtersEndereco.UF })
    if (filtersDepAdm.adminDep) f.push({ field: 'nmDependenciaAdministrativa', value: filtersDepAdm.adminDep })

    f.push({ field: 'exportarPlanilha', value: true });

    this.Toast.setState({
      message: {
        message: "A Planilha está sendo gerada para Exportação.",
        type: 'info',
        open: true,
        loading: true,
        autoHide: false,
      }
    })

    RelatorioService.exportReportAllSchools(f)
      .then(res => download(res))
  }

  buscarEscolas = (forceRefresh = false) => {
    this.setState({ loading: true });
    const filters = [{ 'field': "forceRefresh", 'value': forceRefresh }];

    RelatorioService.filtersReportAllSchools(filters)
      .then(res => {        
        this.setState({ 
          ultimaChamada: res.data.ultimaChamada,
          loading: false,
          schools: [ ...res.data.cache ]
        });   
      })
      .catch(error => {
        const e = errors(error);
        this.setState({ loading: false });

        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      });
  }

  componentDidMount() { 
    this.buscarEscolas();
  }

  // LIMPAR FILTROS
  handleFilterClear = () => {
    this.EnderecoFilter.setState({
      filters: {
        UF: '',
      }
    })

    this.DependenciaAdministrativaFilter.setState({
      filters: {
        adminDep: '',
      }
    })
  }

  render() {
    const pageName = 'Escolas';
    const links = [
      {
        path: null,
        name: 'Relatórios'
      }
    ];

    return (
      <Layout>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>
          {pageName}
          { this.state.ultimaChamada && 
            <Cache ultimaAtualizacao={this.state.ultimaChamada} action={() => this.buscarEscolas(true)} /> 
          }
        </Title>

        <Filters
          handleFilter={this.ApplyFilters}
          handleFilterClear={this.handleFilterClear}
        >
          <Grid item container spacing={3}>
            <EnderecoFilter
              parentRef={this.setEnderecoFilter}
              disabledCities={true}
            />
            <DependenciaAdministrativaFilter
              parentRef={this.setDependenciaAdministrativaFilter}
              withFederal={true}
            />
          </Grid>
        </Filters>
        <Grid container spacing={3}>
          <AuthorizedElement roles={[__RELATORIO_ESCOLAS_EXPORTAR]}>
            <ButtonExport
              title="Exportar Planilha"
              onClick={this.handleExportChange}
            />
          </AuthorizedElement>
        </Grid>

        {this.state.loading ?
          <Loading />
          :
          <DadosEscolas schools={this.state.schools} />
        }
      </Layout>
    )
  }
}

export default withRouter(Escolas);