import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterDataProvaFase1 } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import Table from '../../components/Table/Table';

import { evasaoLimiterMask } from '../../helper/MaskHelper';

export class DadosMunicipios extends Component {
    constructor (props) {
        super(props)
        props.parentRef(this);
        this.state = {
            municipios: [],
        }
    }

    componentDidMount () { this.props.loadContext() }

    // SOMA DE MUNICIPIOS
    sumMunicipios = () => this.state.municipios.length;

    // SOMA GERAL PARA ESCOLAS INSCRITAS
    sumEscolasInscritas = () => this.state.municipios.reduce((total, mun) => total + mun.qtdEscolasInscritasTotal, 0);

    // SOMA GERAL ESCOLAS MUNICPAIS
    sumEscolasMunicipais = () => this.state.municipios.reduce((total, mun) => total + mun.qtdEscolasMunicipais, 0);
	sumEscolasMunicipaisInscritas = () => this.state.municipios.reduce((total, mun) => total + mun.qtdEscolasMunicipaisInscritas, 0);
	sumAlunosPrevistosMunicipais = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosPrevistosMunicipais, 0);
	sumAlunosParticipantesMunicipais = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosParticipantesMunicipal, 0);
		
	sumEscolasOutras = () => this.state.municipios.reduce((total, mun) => total + mun.qtdEscolasOutras, 0);
	sumEscolasOutrasInscritas = () => this.state.municipios.reduce((total, mun) => total + mun.qtdEscolasOutrasInscritas, 0);
	sumAlunosPrevistosOutras = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosPrevistosOutras, 0);
	sumAlunosParticipantesOutras = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosParticipantesOutras, 0);

    sumAlunosParticipantesFase2Mirim1 = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosParticipantesFase2Mirim1, 0);
    sumAlunosParticipantesFase2Mirim2 = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosParticipantesFase2Mirim2, 0);
    sumAlunosParticipantesFase2 = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosParticipantesFase2, 0);

    sumAlunosInscritosFase2Mirim1 = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosInscritosFase2Mirim1, 0);
    sumAlunosInscritosFase2Mirim2 = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosInscritosFase2Mirim2, 0);
    sumAlunosInscritosFase2 = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosInscritosFase2, 0);

    // SOMA GERAL PARA ALUNOS PREVISTOS
    sumAlunosPrevistos = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosPrevistosTotal, 0);

    // SOMA GERAL PARA ALUNOS PARTICIPANTES
    sumAlunosParticipantes = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosParticipantesTotal, 0);

    // CALCULAR EVASÃO
    calcEvasao = () => 100 - ((this.sumAlunosParticipantes() * 100) / this.sumAlunosPrevistos());

    render () {
        const columns = [
            { label: 'UF', name: 'nmUf', func: null, key: true, witdh: '10', },
            { label: 'Municipio', name: 'nmMunicipio', func: null, key: false, width: '12', },
			{ label: 'Escolas(M)', name: 'qtdEscolasMunicipais', func: null, key: false, width: '12', },
			{ label: 'Escolas Inscritas(M)', name: 'qtdEscolasMunicipaisInscritas', func: null, key: false, width: '12', },
			{ label: 'Alunos Previstos(M)', name: 'qtdAlunosPrevistosMunicipais', func: null, key: false, width: '12', },
			{ label: 'Alunos Participantes(M)', name: 'qtdAlunosParticipantesMunicipal', func: null, key: false, width: '12', },
            
			{ label: 'Escolas(E+F)', name: 'qtdEscolasOutras', func: null, key: false, width: '12', },
			{ label: 'Escolas Inscritas(E+F)', name: 'qtdEscolasOutrasInscritas', func: null, key: false, width: '12', },
			{ label: 'Alunos Previstos(E+F)', name: 'qtdAlunosPrevistosOutras', func: null, key: false, width: '12', },
			{ label: 'Alunos Participantes(E+F)', name: 'qtdAlunosParticipantesOutras', func: null, key: false, width: '12', },
            
			{ label: 'Escolas Inscritas(Total)', name: 'qtdEscolasInscritasTotal', func: null, key: false, width: '12', },
            { label: 'Alunos Inscritos(Total)', name: 'qtdAlunosPrevistosTotal', func: null, key: false, width: '12', },
            { label: 'Alunos Participantes(Total)', name: 'qtdAlunosParticipantesTotal', func: null, key: false, width: '12', },
            { label: 'Evasão Total', name: 'evasaoTotal', func: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask : () => "Prova Não Realizada", key: false, width: '10', },

            { label: 'Fase 2 - Mirim 1 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim1', func: null, key: false, width: '10', },
            { label: 'Fase 2 - Mirim 2 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim2', func: null, key: false, width: '10', },
            { label: 'Fase 2 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2', func: null, key: false, width: '12', },

            { label: 'Fase 2 - Mirim 1 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim1', func: null, key: false, width: '10', },
            { label: 'Fase 2 - Mirim 2 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim2', func: null, key: false, width: '10', },
            { label: 'Fase 2 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2', func: null, key: false, width: '12', },
        ];
        
        const actions = [];
		
		let municipiosContent = [...this.state.municipios];

		municipiosContent.push({
			nmUf: "",
			nmMunicipio: this.sumMunicipios(),
			qtdAlunosParticipantesMunicipal: this.sumAlunosParticipantesMunicipais(),
			qtdAlunosParticipantesOutras: this.sumAlunosParticipantesOutras(),
			qtdAlunosParticipantesTotal: this.sumAlunosParticipantes(),
			qtdAlunosPrevistosMunicipais: this.sumAlunosPrevistosMunicipais(),
			qtdAlunosPrevistosOutras: this.sumAlunosPrevistosOutras(),
			qtdAlunosPrevistosTotal: this.sumAlunosPrevistos(),
			qtdEscolasInscritasTotal: this.sumEscolasInscritas(),
			qtdEscolasMunicipais: this.sumEscolasMunicipais(),
			qtdEscolasMunicipaisInscritas: this.sumEscolasMunicipaisInscritas(),
			qtdEscolasOutras: this.sumEscolasOutras(),
			qtdEscolasOutrasInscritas: this.sumEscolasOutrasInscritas(),
			qtdEscolasTotal: null,
            qtdAlunosInscritosFase2Mirim1: this.sumAlunosInscritosFase2Mirim1(),
            qtdAlunosInscritosFase2Mirim2: this.sumAlunosInscritosFase2Mirim2(),
            qtdAlunosInscritosFase2: this.sumAlunosInscritosFase2(),
            qtdAlunosParticipantesFase2Mirim1: this.sumAlunosParticipantesFase2Mirim1(),
            qtdAlunosParticipantesFase2Mirim2: this.sumAlunosParticipantesFase2Mirim2(),
            qtdAlunosParticipantesFase2: this.sumAlunosParticipantesFase2(),
			evasaoTotal: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask(this.calcEvasao()) : () => "Prova Não Realizada",
		});
        
        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={municipiosContent}
                        actions={actions}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(DadosMunicipios);