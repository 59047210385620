import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import SelectOption from '../../components/Inputs/Select/Select';

import { optionsAdminDep, optionsAdminDepWithoutFederal } from '../../variables/Enums/DependenciaAdministrativa';

export class DependenciaAdministrativaFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
                adminDep: '',
            }
        }
    }

    // INPUTS
    handleChange = e => {
        this.setState({ 
            filters: { [e.target.name]: e.target.value, } 
        })
    }

    render () {
        return (
            <Grid item sm={12} lg={2}>
                <SelectOption
                    label="Dep. Administrativa"
                    name='adminDep'
                    value={this.state.filters.adminDep}
                    onChange={(e) => this.handleChange(e)}
                    options={ this.props.withFederal ? optionsAdminDep : optionsAdminDepWithoutFederal}
                />
            </Grid>
        )
    }
}

export default DependenciaAdministrativaFilter;