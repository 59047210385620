import InicioInscricao from '../../views/Inscricao/Inicio';
import FormInscricao from '../../views/Inscricao/Form';
import Fase2 from '../../views/Fase2/Fase2';
import RelatorioEscola from '../../views/Relatorio/Escola';
import ReciboEscola from '../../views/Recibo/Escola';
import { 
    __INSCRICAO_EDITAR,
    __RELATORIO_ESCOLA_VISUALIZAR,
    __RECIBO_ESCOLA,
    __DASHBOARD_INSCRICAO_ALUNOS_CLASSIFICADOS,
} from '../../security/RoleConfiguration';

export const path = '/admin/inscricao';

export const listRoutes = [
    {
        path: `${path}/:id/relatorio`,
        component: RelatorioEscola,
        roles: [ __RELATORIO_ESCOLA_VISUALIZAR ],
    },
    {
        path: `${path}/:id/recibo`,
        component: ReciboEscola,
        roles: [ __RECIBO_ESCOLA ],
    },
    {
        path: `${path}/:id/editar`,
        component: FormInscricao,
        roles: [ __INSCRICAO_EDITAR ],
    },
    {
        path: `${path}/:id/fase-2`,
        component: Fase2,
        roles: [ __DASHBOARD_INSCRICAO_ALUNOS_CLASSIFICADOS ],
    },
];

export const listRoutesPublic = [
    {
        path: `/inscricao`,
        component: InicioInscricao,
        roles: [],
    },
    {
        path: `/inscricao/:id`,
        component: FormInscricao,
        roles: [],
    },
];

export default listRoutes;