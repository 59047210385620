import React, { Fragment } from 'react';
import clsx from  'clsx';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import useStyles from './Style';
import List from './List';

import menuList from '../../variables/Links/Menu';

const DrawerLeft = (props) => {
  const classes = useStyles();

  let listItems = menuList.map( (menu, key) => {     
    return (
      <Fragment key={key} >
        <List items={menu.items} category={menu.category} />
      </Fragment>
    )
  });  

  return (
    <Drawer
      className={clsx(classes.drawer, 'no-print')}
      variant="persistent"
      anchor="left"
      open={props.open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={props.click}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />

      { listItems }
    </Drawer>
  );
}

export default DrawerLeft;