import API, { API as Instance } from './API';

const path = `inscricao`;

const InscricaoService = {
    // LISTAR UM
    find: async id => await API.find(path, id),

    // ADICIONAR
    add: async (inep, inscricao) => await API.add(`${path}/${inep}`, inscricao),

    // EDITAR
    edit: async (inep, inscricao) => await API.edit(path, inep, inscricao),

    // REMOVER
    remove: async id => await API.remove(path, id),

    informarParticipantes: async (inep, inscricao) => await Instance.put(`${path}/${inep}/informar-participantes`, inscricao),

    verificarOrdenacao: async () => await Instance.get(`${path}/verificar-ordenacao`),
    ordenarEscolasIncritas: async () => await Instance.post(`${path}/ordenar-impressao`),

    calcularMateriaisEscolasInscritas: async () => await Instance.post(`${path}/calcular-materiais`),
}

export const ReciboService = {
    // EXPORTAR
    export: async (id, filters) => await API.export(`${path}/${id}/recibo`, filters),

    // RECIBO
    receipt: async id => await API.list(`${path}/${id}/recibo`),
}

export default InscricaoService;