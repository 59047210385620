import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 300;

const useStyles = makeStyles( 
    (theme) => (
        {
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
            },
            drawerPaper: {
                width: drawerWidth,
            },
            drawerHeader: {
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(0, 1),
                ...theme.mixins.toolbar,
                justifyContent: 'flex-end',
            },
            nested: {
                paddingLeft: theme.spacing(4),
            },
            drawerEnd: {
                flex: 1,
                display: 'flex',
                alignItems: 'flex-end',
            },
            darkMode: {
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(1, 2),
                justifyContent: 'flex-start',
            }
        }
    ) 
);

export default useStyles;