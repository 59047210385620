import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { loadContext } from '../../store/actions/Contexto';
import { isPeriodoInscricoes } from '../../helper/ContextHelper';

import { useKeycloak } from '@react-keycloak/web'

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { MenuMirim, BannerHeader } from './Style';

import useStyles, { ButtonAppBarCollapse } from './Style';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import {
    __ADMINISTRADOR,
    __CENTRAL
} from '../../security/RoleConfiguration';

const Header = props => {
    const classes = useStyles();

    const contexts = useSelector(store => store.contexts);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadContext());
    }, [dispatch])

    return (
        <div className={props.className}>
            <BannerHeader />

            <MenuMirim>
                <ButtonAppBarCollapse className={classes.menuColapsado}>
                    {isPeriodoInscricoes(contexts) &&
                        <MenuItem style={{ backgroudColor: '#E60480' }}>
                            <ButtonInscricao />
                        </MenuItem>
                    }
                    <MenuItem style={{ backgroudColor: '#E60480' }}>
                        <ButtonCalendario />
                    </MenuItem>
                    <MenuItem style={{ backgroudColor: '#E60480' }}>
                        <ButtonListaEscolasPublico />
                    </MenuItem>
                    <MenuItem style={{ backgroudColor: '#E60480' }}>
                        <ButtonRegulamento />
                    </MenuItem>
                    <MenuItem>
                        <ButtonAreaRestrita />
                    </MenuItem>
                </ButtonAppBarCollapse>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    align="center"
                    className={classes.menuNormal}
                >
                    {isPeriodoInscricoes(contexts) &&
                        <Grid item xs={12} sm={3} lg={3} spacing={1}>
                            <ButtonInscricao />
                        </Grid>
                    }
                    <Grid item xs={12} sm={2} lg={2} spacing={1}>
                        <ButtonCalendario />
                    </Grid>
                    <Grid item xs={12} sm={2} lg={2} spacing={1}>
                        <ButtonListaEscolasPublico />
                    </Grid>
                    <Grid item xs={12} sm={2} lg={2} spacing={1}>
                        <ButtonRegulamento />
                    </Grid>
                    <Grid item xs={12} sm={2} lg={3} spacing={1}>
                        <ButtonAreaRestrita />
                    </Grid>
                </Grid>
            </MenuMirim>
        </div>
    )
}

const ButtonAreaRestrita = () => {
    const classes = useStyles();
    const history = useHistory();

    const [keycloak,] = useKeycloak();
    const handleSubmitSignIn = () => keycloak.login({ redirectUri: `${process.env.REACT_APP_PUBLIC_URL}/admin/inicial` });
    const handleRestrictArea = () => history.push(`/admin${AuthorizedFunction([ __ADMINISTRADOR, __CENTRAL]) ? `/inicial` : `/inicio`}`);

    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.buttonMenu}
            onClick={!keycloak.authenticated ? handleSubmitSignIn : handleRestrictArea}
        >
            Acesso a Área Restrita
        </Button>
    )
}

const ButtonRegulamento = () => {
    const classes = useStyles();

    const history = useHistory();
    const handleRegulamento = () => {
        history.push({ pathname: `/regulamento` });
    }

    return (
        <Button
            variant="contained"
            color="secondary"
            disableElevation
            className={classes.buttonMenu}
            style={{ backgroundColor: '#E60480' }}
            onClick={handleRegulamento}
        >
            Regulamento
        </Button>
    )
}

const ButtonListaEscolasPublico = () => {
    const classes = useStyles();

    const history = useHistory();
    const handleListaEscolasPublico = () => {
        history.push({ pathname: `/escolas` });
    }

    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.buttonMenu}
            onClick={handleListaEscolasPublico}
        >
            Lista de Escolas
        </Button>
    )
}

const ButtonCalendario = () => {
    const classes = useStyles();

    const history = useHistory();
    const handleCalendario = () => {
        history.push({ pathname: `/calendario` });
    }

    return (
        <Button
            variant="contained"
            color="secondary"
            disableElevation            
            className={classes.buttonMenu}
            style={{ backgroundColor: '#E60480' }}
            onClick={handleCalendario}
        >
            Calendário
        </Button>
    )
}

const ButtonInscricao = () => {
    const classes = useStyles();

    const history = useHistory();
    const [keycloak,] = useKeycloak();
    
    const handleFormularioInscricao = () => {
        if (keycloak.authenticated)
            keycloak.logout({ redirectUri: `${process.env.REACT_APP_PUBLIC_URL}/inscricao` });
        
        history.push({ pathname: `/inscricao` });
    }

    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.buttonMenu}
            onClick={handleFormularioInscricao}
        >
            Escola, clique aqui e se inscreva
        </Button>
    )
}

export default Header;
