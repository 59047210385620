import React from 'react';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Header from '../../components/Header/Header';
import { Download } from '../../components/Download/Download';
import Title from '../../components/Title/Title';

import Preliminares from './Preliminares';
import Participantes from './Participantes';
import Inscricao from './Inscricao';
import Fases from './Fases';
import ProvaFase1 from './ProvaFase1';
import MaterialDigitalFase1 from './MaterialDigitalFase1';
import AplicacaoProvaFase1 from './AplicacaoProvaFase1';
import CorrecaoProvaFase1 from './CorrecaoProvaFase1';
import ClassificacaoFase2 from './ClassificacaoFase2';
import ProvaFase2 from './ProvaFase2';
import MaterialDigitalFase2 from './MaterialDigitalFase2';
import CorrecaoProvaFase2 from './CorrecaoProvaFase2';
import Desempate from './Desempate';
import DisposicoesGerais from './DisposicoesGerais';
import Anexo1 from './Anexo1';
import Anexo2 from './Anexo2';
import Anexo3 from './Anexo3';

import pdfRegulamento from '../../assets/docs/regulamento.pdf';

import useStyles from './Style';

import Layout from '../../layouts/public/Public';

const Regulamento = () => {
    const classes = useStyles();
    return (
        <Layout>
            <Header />

            <Container maxWidth="lg" className={classes.container} >
                <Title variant="h3">
                    EM BREVE
                </Title>
            </Container>
        </Layout>
    )
}

export default Regulamento;