import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isPeriodoInscricoes } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert'
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { SearchOutlined } from '@material-ui/icons';

import EscolaService from '../../services/Escola';

import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Text from '../../components/Inputs/Text/Text';
import { LinkRegular, LinkSimple } from '../../components/Link/Link';

import ButtonsForm from '../../forms/Buttons/ButtonsForm';

import {
    isBlank,
    isBlankHelperText,
    isMecCodeHelperText,
    isMecCodeInvalid,
} from '../../helper/ValidationHelper';
import { mecCodeMask } from '../../helper/MaskHelper';

import keycloak from '../../security/keycloak';

import Layout from '../../layouts/public/Public';

export class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingButtonFind: false,
            loadingButtonSave: false,
            inepParaBusca: '',
            mecCode: null,
            name: null,
            isInscrita: false,
            isInvalida: false,
            isNovaSemValidacao: false,
            errors: {
                inepParaBusca: false,
            },
            helpers: {
                inepParaBusca: null,
            },
        }
    }

    setToast = t => this.Toast = t;

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.name === 'inepParaBusca' ? mecCodeMask(e.target.value) : e.target.value,
            errors: { ...this.state.errors, [e.target.name]: false },
            helpers: { ...this.state.helpers, [e.target.name]: false }
        });
    }

    handleSubmit = () => {
        this.setState({ loading: true, loadingButtonFind: true });

        if (!this.isValid(this.state.inepParaBusca)) {
            this.setState({ loading: false, loadingButtonFind: false });
            return;
        }

        EscolaService.buscarParaInscricao(this.state.inepParaBusca)
            .then(res => {
                if (res.data) {
                    const school = res.data;
                    this.setState({
                        ...this.state,
                        mecCode: school.cdMecEscola,
                        name: school.nmEscola,
                        isInscrita: school.inscricao ? true : false,
                        isInvalida: false,
                        isNovaSemValidacao: school.inNova && school.nmStatus === 'EM_ANALISE' ? true : false,
                    });

                    if ((!school.inscricao && !school.inNova) || (!school.inscricao && school.inNova && school.nmStatus === 'VALIDA')) 
                        this.props.history.push({ pathname: `/inscricao/${school.cdMecEscola}`, state: { history: { path: `/inscricao`, state: null, }}});

                } else {
                    this.setState({
                        ...this.state,
                        mecCode: null,
                        name: null,
                        isInscrita: false,
                        isInvalida: true,
                        isNovaSemValidacao: false,
                    });
                }
            })
            .catch(error => {
                this.Toast.setState({
                    message: {
                        message: "Ocorreu um Problema ao Buscar a Escola.",
                        type: "error",
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loading: false, loadingButtonFind: false }))
    }

    isValid = (inepParaBusca) => {
        if (isBlank(inepParaBusca) || isMecCodeInvalid(inepParaBusca)) {
            this.setState({
                errors: {
                    inepParaBusca: isBlank(inepParaBusca) || isMecCodeInvalid(inepParaBusca) ? true : false,
                },
                helpers: {
                    inepParaBusca: isBlank(inepParaBusca) ? isBlankHelperText() : isMecCodeInvalid(inepParaBusca) ? isMecCodeHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    componentDidMount() {
        this.props.loadContext();

        if (!isPeriodoInscricoes(this.props.contexts)) {
            this.Toast.setState({
                message: {
                    message: "Ação de Iniciar Inscrição Inválida. Estamos Fora do Período de Inscrições. Aguarde Nova Abertura do Período de Inscrição.",
                    type: "error",
                    open: true
                }
            })

            setTimeout(() => this.props.history.push(`/`), 3000);
        }
    }

    render() {
        const page = `Olimpíada Mirim - Identificação da Escola`;

        return (
            <Layout>
                <Toast parentRef={this.setToast} />

                <Container component="main" maxWidth="xl">
                    <Box mt={10}>
                        <Title>{page}</Title>

                        {(this.state.isInvalida) ?
                            <Grid item container spacing={3} align='center'>
                                <Grid item sm={8} lg={8}>
                                    <Alert severity="error">
                                        <Typography variant="h6" style={{ display: "flex" }}>
                                            Escola Não Encontrada
                                        </Typography>
                                        <Typography variant="body2" style={{ display: "flex" }}>
                                            Verifique se o <b>&nbsp; Código INEP (8 dígitos) &nbsp;</b> da sua escola está correto e tente novamente.
                                        </Typography>
                                    </Alert>
                                </Grid>
                            </Grid>
                        : (this.state.mecCode) ? 
                            (this.state.isInscrita) ?
                                <Grid item container spacing={3} align='center'>
                                    <Grid item sm={8} lg={8}>
                                        <Alert severity="warning">
                                            <Typography variant="h6" style={{ display: "flex" }}>
                                                Escola Já Inscrita
                                            </Typography>
                                            <Typography variant="body2" style={{ display: "flex" }}>
                                                A Escola <b>&nbsp; {this.state.name} ({this.state.mecCode}) &nbsp;</b> já está inscrita nesta edição da Olimpíada Mirim.
                                            </Typography>
                                            <Typography variant="body2" style={{ display: "flex" }}>
                                                Se você já fez a inscrição de sua Escola e deseja fazer alguma alteração na ficha de inscrição,
                                                <LinkSimple link={keycloak.createLoginUrl()}>&nbsp; CLIQUE AQUI &nbsp;</LinkSimple> para efetuar login e ter acesso a ficha.
                                            </Typography>
                                        </Alert>
                                    </Grid>
                                </Grid>
                            : (!this.state.isInscrita && this.state.isNovaSemValidacao) &&
                                <Grid item container spacing={3} align='center'>
                                    <Grid item sm={8} lg={8}>
                                        <Alert severity="warning">
                                            <Typography variant="h6" style={{ display: "flex" }}>
                                                Solicitação de Escola Nova Ainda Não Tratada
                                            </Typography>
                                            <Typography variant="body2" style={{ display: "flex" }}>
                                                A Escola <b>&nbsp; {this.state.name} ({this.state.mecCode}) &nbsp;</b> já está presente na base na Olimpíada Mirim, mas ainda <b>&nbsp; NÃO &nbsp;</b> está disponível para Inscrição.
                                            </Typography>
                                            <Typography variant="body2" style={{ display: "flex" }}>
                                                Aguarde o Email de Tratamento da Central da OBMEP para a Escola, para Iniciar esta Inscrição.
                                            </Typography>
                                        </Alert>
                                    </Grid>
                                </Grid>
                            : null
                        }

                        {(!this.state.mecCode || (this.state.mecCode && this.state.isInscrita) || this.state.isInvalida || this.state.isNovaSemValidacao) &&
                            <>
                                <Grid container spacing={3} align='center'>
                                    <Grid item sm={9} lg={9}>
                                        <Text
                                            autoFocus
                                            required
                                            name="inepParaBusca"
                                            label="Código da Escola (Fornecido Pelo MEC/INEP - 8 Dígitos)"
                                            value={this.state.inepParaBusca}
                                            error={this.state.errors.inepParaBusca}
                                            onChange={this.handleChange}
                                            helperText={this.state.helpers.inepParaBusca}
                                            inputProps={{ maxLength: 8 }}
                                        />
                                    </Grid>
                                    <Grid item sm={3} lg={3}>
                                        <ButtonsForm
                                            startIcon={<SearchOutlined />}
                                            onClick={this.handleSubmit}
                                            onBack={() => this.props.history.push({ pathname: "/" })}
                                            loading={this.state.loadingButtonFind}
                                            name={"Buscar"}
                                            nameOnBack={"Voltar"}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item container spacing={3} align='center'>
                                    <Grid item sm={8} lg={8}>
                                        <Alert severity="info">
                                            <Typography variant="body2" style={{ display: "flex" }}>
                                                A escola, ao inscrever-se na 1ª Olimpíada Mirim, concorda integralmente com todas as condições e regras previstas no
                                                <LinkRegular to={{ pathname: "regulamento", state: { history: { path: `/inscricao`, state: null, } }}} > REGULAMENTO</LinkRegular>.
                                            </Typography>
                                            <Typography variant="body2" style={{ display: "flex" }}>
                                                Se sua escola foi criada recentemente e ainda não está inserida em nossa base de dados, por favor acesse o formulário neste
                                                <LinkRegular to={{ pathname: "escola/nova", state: { history: { path: `/inscricao`, state: null, } }}}> LINK</LinkRegular> e preencha todos os campos.
                                            </Typography>
                                        </Alert>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Box>
                </Container>
            </Layout>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(List));