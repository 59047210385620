import API from './API';

const path = `relatorios`;

const RelatorioService = {

    // RELATÓRIO DE ESCOLAS
    reportAllSchools: async () => await API.list(`${path}/escolas`),
    filtersReportAllSchools: async filters => await API.filters(`${path}/escolas`, filters),
    exportReportAllSchools: async filters => await API.export(`${path}/escolas`, filters),

    // RELATÓRIO DE DEPENDENCIAS ADMINISTRATIVAS
    reportDependenciasAdministrativas: async () => await API.list(`${path}/dependencias-administrativas`),
    filtersReportDependenciasAdministrativas: async filters => await API.filters(`${path}/dependencias-administrativas`, filters),
    exportReportDependenciasAdministrativas: async filters => await API.export(`${path}/dependencias-administrativas`, filters),

    // RELATÓRIO DE MUNICÍPIOS
    reportMunicipios: async () => await API.list(`${path}/municipios`),
    filtersReportMunicipios: async filters => await API.filters(`${path}/municipios`, filters),
    exportReportMunicipios: async filters => await API.export(`${path}/municipios`, filters),

    // RELATÓRIO DE UF'S
    reportUfs: async () => await API.list(`${path}/ufs`),
    filtersReportUfs: async filters => await API.filters(`${path}/ufs`, filters),
    exportReportUfs: async filters => await API.export(`${path}/ufs`, filters),

    // RELATÓRIO DE INSCRIÇÕES
    reportInscricoes: async () => await API.list(`${path}/inscricoes`),
    filtersReportInscricoes: async filters => await API.filters(`${path}/inscricoes`, filters),
    exportReportInscricoes: async filters => await API.export(`${path}/inscricoes`, filters),

    // RELATÓRIO PÚBLICO DE ESCOLAS
    reportAllSchoolsPublic: async () => await API.list(`${path}/publico/escolas`),
    filtersReportAllSchoolsPublic: async filters => await API.filters(`${path}/publico/escolas`, filters),
    exportReportAllSchoolsPublic: async filters => await API.export(`${path}/publico/escolas`, filters),

    // RELATÓRIO DE PROVAS ESPECIAIS
    reportProvasEspeciais: async () => await API.list(`${path}/provas-especiais`),
    filtersReportProvasEspeciais: async filters => await API.filters(`${path}/provas-especiais`, filters),
    exportReportProvasEspeciais: async filters => await API.export(`${path}/provas-especiais`, filters),
}

export default RelatorioService;