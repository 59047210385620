import API from './API';

const path = `emails`;

const FileService = {
    // FILTROS
    filters: async filters => await API.filters(path, filters),

    // BUSCAR UM
    find: async id => await API.find(path, id),
    
    // ENVIAR
    send: async email => await API.add(`${path}`, email),

}

export default FileService;