import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';

import { loadContext } from '../../store/actions/Contexto';
import { isPeriodoInscricoes } from '../../helper/ContextHelper';

import { makeStyles } from '@material-ui/core/styles';

import banner from '../../assets/img/banner-principal.png';
import bannerMobile from '../../assets/img/banner-principal-mobile.png';

const useStyles = makeStyles( 
    (theme) => (
        {
            banner: {
                marginTop: theme.spacing(7),
                width: '100%',
                ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
                    marginTop: theme.spacing(6),  
                },
            },
        }
    ) 
);

export const Banner = () => {
    const contexts = useSelector(store => store.contexts);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadContext());
    }, [dispatch])
    
    const [keycloak,] = useKeycloak();

    const history = useHistory();
    const handleInscricao = () => {
        if (isPeriodoInscricoes(contexts)) {
            if (keycloak.authenticated)
                keycloak.logout({ redirectUri: `${process.env.REACT_APP_PUBLIC_URL}/inscricao` });

            history.push({ pathname: `/inscricao` });
        } else
            window.location.replace(`${process.env.REACT_APP_PUBLIC_URL}/calendario`);
    }

    const classes = useStyles();
    return (
        <Link onClick={handleInscricao}>
            <picture>
                <source media="(max-width: 650px)" srcSet={bannerMobile}/>
                <source media="(max-width: 980px)" srcSet={banner}/>
                <img src={banner} className={classes.banner} alt={process.env.REACT_APP_NAME_GENERAL} />
            </picture>
        </Link>
    )
}

export default useStyles;
