// TIPOS DE VARIAVEIS DE CONTEXTO
export const optionsType = [
    { value: 'BOOLEAN', label: 'Verdadeiro ou Falso' },
    { value: 'LONG', label: 'Numérico' },
    { value: 'TEXTO', label: 'Texto' },
    { value: 'DATA', label: 'Data' },
];

export const returnLabel = value => {
    return value === 'BOOLEAN' ? 'Verdadeiro ou Falso'
            : value === 'LONG' ? 'Numérico'
            : value === 'DATA' ? 'Data' : 'Texto';            
}

export const patternContext = contexts => {
    let array = [];
    contexts.forEach((context) => {
        array.push({
            id: context.cdVariavel,
            name: context.nmVariavel.replace(/ /g, '').concat(context.cdVariavel),
            type: context.nmTipo,
            label: context.nmVariavel,
            value: context.nmValor ? context.nmValor : '',
        });
    })
    return array;
}