import React from 'react';
import Chip from '@material-ui/core/Chip';

export const patternSchools = schools => {
    let array = [];
    schools.forEach((school) => {
        array.push({
            cdMecEscola: school.cdMecEscola,
            nmEscola: school.nmEscola,
            nmUf: school.endereco.municipio.uf.nmUf,
            nmMunicipio: school.endereco.municipio.nmMunicipio,
            cdRegiao: school.endereco.municipio.cdRegiao,
            qtdAlunosPrevistosTotal: school.inscricao ? school.inscricao.qtdAlunosPrevistosTotal : "Sem Inscrição",
            qtdAlunosParticipantesTotal: school.inscricao ? school.inscricao.qtdAlunosParticipantesTotal : "Sem Inscrição",
            evasaoTotal: school.inscricao ? school.inscricao.evasaoTotal : "0",
            idResponsavel: school.responsavel ? school.responsavel.cdResponsavel : null,
            isInscrita: school.inscricao !== null ? true : false,
            nmStatus: school.nmStatus,
            nmDependenciaAdministrativa: school.nmDependenciaAdministrativa,
            inscrita: school.inscrita ? "Sim" : "Não",
            downloadFase2: school.downloadFase2,
            qtdAlunosDeficienciaParcialMirim1: school.inscricao ? school.inscricao.qtdAlunosDeficienciaParcialMirim1 : "0",
            qtdAlunosDeficienciaTotalMirim1: school.inscricao ? school.inscricao.qtdAlunosDeficienciaTotalMirim1 : "0",
            qtdAlunosDeficienciaParcialMirim2: school.inscricao ? school.inscricao.qtdAlunosDeficienciaParcialMirim2 : "0",
            qtdAlunosDeficienciaTotalMirim2: school.inscricao ? school.inscricao.qtdAlunosDeficienciaTotalMirim2 : "0",
        });
    });

    return array;
}

export const optionsStatus = [
    { value: 'VALIDA', label: 'Válida' },
    { value: 'EM_ANALISE', label: 'Em Análise' },
    { value: 'INVALIDA', label: 'Inválida' }
];

export const optionsOrigem = [
    { value: false, label: 'Importada da Base MEC' },
    { value: true, label: 'Cadastrada por Responsável' }
];

export const optionsNivel = [
    { value: 'MIRIM_1', label: 'Mirim 1' },
    { value: 'MIRIM_2', label: 'Mirim 2' },
];

export const patternLabelInscrita = label => {
    const color = label === "Sim" ? "#0da627" : "#a6120d";
    return <Chip size="small" label={label} style={{ backgroundColor: color, fontWeight: '800', color: "white"}} />
}