export const patternMails = (emails) => {
    let array = [];
    emails.forEach((email) => {
        array.push({
            cdEmail: email.cdEmail,
            nmEmail: email.nmTipo !== 'CRIACAO_ESCOLA_NOVA' ? email.escola.nmEmail : 'olimpiadamirim@obmep.org.br',
            nmTipo: email.nmTipo,
            cdEscola: email.escola.cdMecEscola,
            nmEscola: email.escola.nmEscola,
        });
    })
    return array;
}

export const patternTypeMail = type => type === 'CRIACAO_ESCOLA_NOVA' ? 'Criação de Nova Escola' : 'Avaliação de Nova Escola';

export const optionsType = [
    { value: 'CRIACAO_ESCOLA_NOVA', label: 'Criação de Nova Escola' },
    { value: 'AVALIACAO_ESCOLA_NOVA', label: 'Avaliação de Nova Escola' },
];