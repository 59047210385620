import React, { useEffect } from "react";

import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

export const AlertaEscola = ({ escola }) => {
    useEffect(() => { }, [escola])

    return (
        <>
            {escola && escola.inscricao &&
                <Alert severity="info" style={{ marginTop: '10px' }}>
                    <Typography variant="body1">
                        <b>{escola.nmEscola} ({escola.cdMecEscola})</b><br />
                    </Typography>
                    <Typography variant="body2">
                        <b>Mirim 1</b>: Total de <b>{escola.inscricao.qtdAlunosInscritosFase2Mirim1}</b> Cadastrados para <b>{escola.inscricao.mirim1.qtdMaximaAlunosClassificaveis}</b> Classificaveis.<br />
                        <b>Mirim 2</b>: Total de <b>{escola.inscricao.qtdAlunosInscritosFase2Mirim2}</b> Cadastrados para <b>{escola.inscricao.mirim2.qtdMaximaAlunosClassificaveis}</b> Classificaveis.<br />
                    </Typography>
                </Alert>
            }
        </>
    )
}

export function escolaComCadastroAlunoDisponivel(escola) {
    if (escola && escola.inscricao && (escola.inscricao.mirim1.qtdMaximaAlunosClassificaveis > escola.inscricao.qtdAlunosInscritosFase2Mirim1 || 
            escola.inscricao.mirim2.qtdMaximaAlunosClassificaveis > escola.inscricao.qtdAlunosInscritosFase2Mirim2)) {
        return true;
    }

    return false;
}

export function escolaPodeCadastrarMaisAlunosNoNivel(escola, nivel) {
    if (escola && escola.inscricao &&
        ((nivel === 1 && escola.inscricao.mirim1.qtdMaximaAlunosClassificaveis > escola.inscricao.qtdAlunosInscritosFase2Mirim1) ||
            (nivel === 2 && escola.inscricao.mirim2.qtdMaximaAlunosClassificaveis > escola.inscricao.qtdAlunosInscritosFase2Mirim2)))
        return true;

    return false;
}

export function escolaNaoInformouAlunosPrevistosParaNivel(escola, nivel) {
    if (escola && escola.inscricao &&
        ((nivel === 1 && escola.inscricao.mirim1.qtdMaximaAlunosClassificaveis === 0) || (nivel === 2 && escola.inscricao.mirim2.qtdMaximaAlunosClassificaveis === 0)))
        return true;

    return false;
}

export function escolaJaCadastrouTodosOsAlunosDoNivel(escola, nivel) {
    if (escola && escola.inscricao &&
        ((nivel === 1 && escola.inscricao.mirim1.qtdMaximaAlunosClassificaveis === escola.inscricao.qtdAlunosInscritosFase2Mirim1) ||
            (nivel === 2 && escola.inscricao.mirim2.qtdMaximaAlunosClassificaveis === escola.inscricao.qtdAlunosInscritosFase2Mirim2)))
        return true;

    return false;
}

export default AlertaEscola;