import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import SelectOption from '../../components/Inputs/Select/Select';
import Toast from '../../components/Toast/Toast';

import UFService from '../../services/UF';
import { patternUF } from '../../variables/Enums/UF';

import CidadeService from '../../services/Cidade';
import { patternCity, patternRegion } from '../../variables/Enums/Cidade';

export class EnderecoFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
                UF: '',
                city: '',
                region: '',
            },
            disabled: {
                city: true,
            },
            optionsUF: [],
            optionsCity: [],
            optionsRegion: [],
        }
    }

    // INPUTS
    handleChange = e => {
        if (!this.props.disabledCities && e.target.name === "UF")
            this.findCities(e.target.value, null);
        else
            this.setState({
                filters: {...this.state.filters, [e.target.name]: e.target.value },
            })
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // BUSCAR MUNICIPIOS POR UF
    findCities = (uf, citySelected) => {
        const f = [];

        if (!uf) {
            this.setState({ 
                filters: {
                    UF: '',
                    city: '',
                    region: '',
                },
                disabled:{
                    city: true,
                },
                optionsCity: []
            })
            return
        }
        
        f.push({ field: "uf", value: uf });

        CidadeService.filters(f)
            .then(res => {
                this.setState({
                    filters: {
                        UF: uf,
                        city: citySelected ?? '',
                    },
                    disabled:{
                        city: false,
                    },
                    optionsCity: [...patternCity(res.data)]
                })
            })
            .catch(err => {
                this.Toast.setState({
                    message: {
                        message: "Não Foi Possível Buscar as Cidades.",
                        type: 'error',
                        open: true
                    }
                })
            })
    }

    componentDidMount () {
        UFService.list()
            .then(res => {
                this.setState({
                    optionsUF: [...patternUF(res.data)]
                })
            })
            .catch(err => {
                this.Toast.setState({
                    message: {
                        message: "Não Foi Possível Buscar as UF's.",
                        type: 'error',
                        open: true
                    }
                })
            })

        if (this.props.withRegion) {
            CidadeService.listRegions()
                .then(res => {
                    this.setState({
                        optionsRegion: [...patternRegion(res.data)]
                    })
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar as Regiões.",
                            type: 'error',
                            open: true
                        }
                    })
                })
        }
    }

    render () {
        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid item sm={12} lg={2}>
                    <SelectOption
                        label="UF"
                        name='UF'
                        value={this.state.filters.UF}
                        onChange={this.handleChange}
                        options={this.state.optionsUF}
                    />
                </Grid>                
                { this.props.withRegion &&
                    <Grid item sm={12} lg={1}>
                        <SelectOption
                            label="Região"
                            name='region'
                            value={this.state.filters.region}
                            onChange={this.handleChange}
                            options={this.state.optionsRegion}
                        />
                    </Grid>
                }
                { !this.props.disabledCities &&
                    <Grid item sm={12} lg={3}>
                        <SelectOption
                            label="Município"
                            name='city'
                            value={this.state.filters.city}
                            onChange={this.handleChange}
                            options={this.state.optionsCity}
                            disabled={this.state.disabled.city}
                        />
                    </Grid>
                }
            </>
        )
    }
}

export default EnderecoFilter;