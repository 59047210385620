import { toDateTimeForCompare, toDateTime } from './DateHelper';

// CONSTANTES DE VARIAVEIS DE CONTEXTO NO BANCO DE DADOS
const EDICAO = 1;
const INICIO_INSCRICOES = 2;
const FIM_INSCRICOES = 3;
const INICIO_ENVIO_OBJETOS_POSTAIS_2_FASE = 4;
const FIM_ENVIO_OBJETOS_POSTAIS_2_FASE = 5;
const INICIO_RASTREAMENTO_OBJETOS_POSTAIS_2_FASE = 6;
const FIM_RASTREAMENTO_OBJETOS_POSTAIS_2_FASE = 7;
const INICIO_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS = 8;
const FIM_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS = 9;
const DATA_PROVA_FASE_1 = 10;
const PERMITE_CADASTRO_ALUNOS_PROVAS_ESPECIAIS = 11;
const DATA_PROVA_FASE_2 = 25;
const INICIO_PERIODO_INSERIR_NOTA_2_FASE = 26;
const FIM_PERIODO_INSERIR_NOTA_2_FASE = 27;

// MÉTODO SIMPLES DE BUSCA DE VARIAVEL DE CONTEXTO
export const takeContext = (contexts, id) => {
    const c = contexts.find(c => c.cdVariavel === id)
    return c ? id === EDICAO || id === PERMITE_CADASTRO_ALUNOS_PROVAS_ESPECIAIS ? c.nmValor : toDateTimeForCompare(c.nmValor) : null;
}

// OPERAÇÕES LÓGICAS BÁSICAS DE CONTEXTUALIZAÇÃO
export const isBefore = (contexts, id) => takeContext(contexts, id) ? takeContext(contexts, id) > new Date() : false;
export const isAfter = (contexts, id) => takeContext(contexts, id) ? takeContext(contexts, id) < new Date() : false;
export const isBeforeOrEqual = (contexts, id) => takeContext(contexts, id) ? takeContext(contexts, id) >= new Date() : false;
export const isAfterOrEqual = (contexts, id) => takeContext(contexts, id) ? takeContext(contexts, id) <= new Date() : false;
export const isBetween = (contexts, idDate1, idDate2) => isAfterOrEqual(contexts, idDate1) && isBeforeOrEqual(contexts, idDate2);
export const isNotBetween = (contexts, idDate1, idDate2) => isBefore(contexts, idDate1) && isAfter(contexts, idDate2);
export const isTrue = (contexts, id) => takeContext(contexts, id) === "true";


/////////////// OS MÉTODOS ABAIXO ESPERAM A PASSAGEM DO STORE COM CONTEXTO CARREGADO NAS VIEWS QUE SERÃO CONTEXTUALIZADAS ///////////////

// MÉTODO DE BUSCA DE NÚMERO DA EDIÇÃO
export const getEdicao = contexts => takeContext(contexts, EDICAO);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE INSCRIÇÕES
export const isPeriodoInscricoes = contexts => isBetween(contexts, INICIO_INSCRICOES, FIM_INSCRICOES);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE REGISTRO DE PARTICIPAÇÕES
export const isPeriodoRegistroParticipacaoCadastroAlunos = contexts => isBetween(contexts, INICIO_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS, FIM_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DEPOIS DO PERÍODO DE REGISTRO DE PARTICIPAÇÕES
export const isAfterPeriodoRegistroParticipacaoCadastroAlunos = contexts => isAfter(contexts, FIM_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS);

// MÉTODO BOLEANO PARA VERIFICAR SE JÁ INICIOU O PERÍODO DE REGISTRO DE PARTICIPAÇÕES
export const isAfterInicioPeriodoRegistroParticipacaoCadastroAlunos = contexts => isAfter(contexts, INICIO_REGISTRO_PARTICIPACAO_CADASTRO_ALUNOS);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DEPOIS DA DATA DA PROVA
export const isAfterDataProvaFase1 = contexts => isAfter(contexts, DATA_PROVA_FASE_1);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DEPOIS DA DATA DA PROVA
export const isAfterDataProvaFase2 = contexts => isAfter(contexts, DATA_PROVA_FASE_2);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE ENVIO DE OBJETOS POSTAIS
export const isPeriodoEnvioObjetosPostais2Fase = contexts => isBetween(contexts, INICIO_ENVIO_OBJETOS_POSTAIS_2_FASE, FIM_ENVIO_OBJETOS_POSTAIS_2_FASE);

// MÉTODO PARA BUSCA DE INICIO DE ENVIO DE OBJETOS POSTAIS
export const getInicioEnvioObjetosPostais2Fase = contexts => toDateTime(takeContext(contexts, INICIO_ENVIO_OBJETOS_POSTAIS_2_FASE));

// MÉTODO PARA BUSCA DE TERMINO DE ENVIO DE OBJETOS POSTAIS
export const getTerminoEnvioObjetosPostais2Fase = contexts => toDateTime(takeContext(contexts, FIM_ENVIO_OBJETOS_POSTAIS_2_FASE));

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE RASTREAMENTO DE OBJETOS POSTAIS
export const isPeriodoRastreamentoObjetosPostais2Fase = contexts => isBetween(contexts, INICIO_RASTREAMENTO_OBJETOS_POSTAIS_2_FASE, FIM_RASTREAMENTO_OBJETOS_POSTAIS_2_FASE);

// MÉTODO PARA BUSCA DE INICIO DE RASTREAMENTO DE OBJETOS POSTAIS
export const getInicioRastreamentoObjetosPostais2Fase = contexts => toDateTime(takeContext(contexts, INICIO_RASTREAMENTO_OBJETOS_POSTAIS_2_FASE));

// MÉTODO PARA BUSCA DE TERMINO DE RASTREAMENTO DE OBJETOS POSTAIS
export const getTerminoRastreamentoObjetosPostais2Fase = contexts => toDateTime(takeContext(contexts, FIM_RASTREAMENTO_OBJETOS_POSTAIS_2_FASE));

// MÉTODO BOLEANO PARA VERIFICAR SE PERMITE CADASTRO ALUNOS PARA PROVAS ESPECIAIS
export const isPermiteCadastroAlunosProvasEspeciais = contexts => isTrue(contexts, PERMITE_CADASTRO_ALUNOS_PROVAS_ESPECIAIS);

// MÉTODO BOLEANO PARA VERIFICAR SE ESTÁ DENTRO DO PERÍODO DE INSERÇÃO DE NOTA DA 2ª FASE 
export const isPeriodoInserirNotaFase2 = contexts => isBetween(contexts, INICIO_PERIODO_INSERIR_NOTA_2_FASE, FIM_PERIODO_INSERIR_NOTA_2_FASE);

// MÉTODO BOLEANO PARA VERIFICAR SE JÁ INICIOU O PERÍODO DE INSERÇÃO DE NOTA DA 2ª FASE 
export const isAfterInicioPeriodoInserirNotaFase2 = contexts => isAfter(contexts, INICIO_PERIODO_INSERIR_NOTA_2_FASE);