import FormEscola from '../../views/Escola/Form';
import FormInscricao from '../../views/Inscricao/Form';
import FormResponsavel from '../../views/Responsavel/Form';
import {
    __ESCOLA_ADICIONAR,
    __ESCOLA_EDITAR,
    __ESCOLA_RESPONSAVEL_EDITAR
} from '../../security/RoleConfiguration';

import { GoToLogin } from './Auth';

export const path = '/admin/escola';

export const listRoutes = [
    {
        path: path + '/adicionar',
        component: FormEscola,
        roles: [ __ESCOLA_ADICIONAR ],
    },
    {
        path: path + '/:id/inscrever',
        component: FormInscricao,
        roles: null,
    },
    {
        path: path + '/:id/editar',
        component: FormEscola,
        roles: [ __ESCOLA_EDITAR ],
    },
    {
        path: path + '/:id/responsavel/editar',
        component: FormResponsavel,
        roles: [ __ESCOLA_RESPONSAVEL_EDITAR ],
    },
];

export const listRoutesPublic = [
    {
        path: '/escola/nova',
        component: FormEscola,
        roles: [],
    },
    {
        path: `/admin/escola`,
        component: GoToLogin,
        roles: [],
    },
]

export default listRoutes;