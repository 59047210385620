export const __ADMINISTRADOR = 'Olimpiada Mirim - Administrador';
export const __CENTRAL = 'Olimpiada Mirim - Central';
export const __ESCOLA = 'Olimpiada Mirim - Escola';

export const __ARQUIVO_ADICIONAR = '__ol-mirim.arquivo.adicionar';
export const __ARQUIVO_EDITAR = '__ol-mirim.arquivo.editar';
export const __ARQUIVO_DOWNLOAD = '__ol-mirim.arquivo.download';
export const __ARQUIVO_REMOVER = '__ol-mirim.arquivo.remover';
export const __ARQUIVO_VISUALIZAR = '__ol-mirim.arquivo.visualizar';

export const __CONTEXTO_ADICIONAR = '__ol-mirim.contexto.adicionar';
export const __CONTEXTO_EDITAR = '__ol-mirim.contexto.editar';
export const __CONTEXTO_REMOVER = '__ol-mirim.contexto.remover';
export const __CONTEXTO_EDITAR_VALOR = '__ol-mirim.contexto.valor.editar';
export const __CONTEXTO_VISUALIZAR_VALOR = '__ol-mirim.contexto.valor.visualizar';
export const __CONTEXTO_VISUALIZAR = '__ol-mirim.contexto.visualizar';
export const __CONTEXTO_ORDENAR_IMPRESSAO = '__ol-mirim.contexto.ordenar-impressao';
export const __CONTEXTO_CALCULAR_MATERIAIS = '__ol-mirim.contexto.calcular-materiais';

export const __EMAIL_VISUALIZAR = '__ol-mirim.email.visualizar';
export const __EMAIL_ENVIAR = '__ol-mirim.email.enviar';

export const __ESCOLA_ADICIONAR = '__ol-mirim.escola.adicionar';
export const __ESCOLA_ADICIONAR_OPCOES_AVANCADAS = '__ol-mirim.escola.adicionar.opcoes-avancadas';
export const __ESCOLA_DASHBOARD = '__ol-mirim.escola.dashboard';
export const __ESCOLA_EDITAR = '__ol-mirim.escola.editar';
export const __ESCOLA_EDITAR_SENHA = '__ol-mirim.escola.editar.senha';
export const __ESCOLA_EXPORTAR = '__ol-mirim.escola.exportar';
export const __ESCOLA_RESPONSAVEL_EDITAR = '__ol-mirim.escola.responsavel.editar';
export const __ESCOLA_VISUALIZAR = '__ol-mirim.escola.visualizar';
export const __ESCOLA_REMOVER = '__ol-mirim.escola.remover';

export const __ALUNO_ADICIONAR = '__ol-mirim.aluno.adicionar';
export const __ALUNO_EDITAR = '__ol-mirim.aluno.editar';
export const __ALUNO_EXPORTAR = '__ol-mirim.aluno.exportar';
export const __ALUNO_VISUALIZAR = '__ol-mirim.aluno.visualizar';
export const __ALUNO_REMOVER = '__ol-mirim.aluno.remover';
export const __ALUNO_TERMO_ADICIONAR = '__ol-mirim.aluno.termo.adicionar';
export const __ALUNO_TERMO_LISTAR = '__ol-mirim.aluno.termo.listar';
export const __ALUNO_TERMO_DOWNLOAD = '__ol-mirim.aluno.termo.download';
export const __ALUNO_TERMO_VALIDAR = '__ol-mirim.aluno.termo.validar';

export const __INSCRICAO_REMOVER = '__ol-mirim.inscricao.remover';
export const __INSCRICAO_EDITAR = '__ol-mirim.inscricao.editar';

export const __RECIBO_EXPORTAR = '__ol-mirim.recibo.exportar';
export const __RECIBO_ESCOLA = '__ol-mirim.recibo.escola';

export const __RELATORIO_ESCOLA_EXPORTAR = '__ol-mirim.relatorio.escola.exportar';
export const __RELATORIO_ESCOLA_VISUALIZAR = '__ol-mirim.relatorio.escola.visualizar';
export const __RELATORIO_DEPENDENCIAS_ADMINISTRATIVAS_EXPORTAR = '__ol-mirim.relatorio.dependencias-administrativas.exportar';
export const __RELATORIO_DEPENDENCIAS_ADMINISTRATIVAS_VISUALIZAR = '__ol-mirim.relatorio.dependencias-administrativas.visualizar';
export const __RELATORIO_ESCOLAS_EXPORTAR = '__ol-mirim.relatorio.escolas.exportar';
export const __RELATORIO_ESCOLAS_VISUALIZAR = '__ol-mirim.relatorio.escolas.visualizar';
export const __RELATORIO_MUNICIPIOS_VISUALIZAR = '__ol-mirim.relatorio.municipios.visualizar';
export const __RELATORIO_MUNICIPIOS_EXPORTAR = '__ol-mirim.relatorio.municipios.exportar';
export const __RELATORIO_UFS_VISUALIZAR = '__ol-mirim.relatorio.ufs.visualizar';
export const __RELATORIO_UFS_EXPORTAR = '__ol-mirim.relatorio.ufs.exportar';
export const __RELATORIO_INSCRICOES_VISUALIZAR = '__ol-mirim.relatorio.inscricoes.visualizar';
export const __RELATORIO_INSCRICOES_EXPORTAR = '__ol-mirim.relatorio.inscricoes.exportar';
export const __RELATORIO_PROVAS_ESPECIAIS_EXPORTAR = '__ol-mirim.relatorio.provas-especiais.exportar';
export const __RELATORIO_PROVAS_ESPECIAIS_VISUALIZAR = '__ol-mirim.relatorio.provas-especiais.visualizar';

export const __DASHBOARD_DADOS_EDITAR = '__ol-mirim.dashboard.dados.editar';
export const __DASHBOARD_INSCRICAO_ALUNOS_CLASSIFICADOS = '__ol-mirim.dashboard.inscricao.alunos-classificados';
export const __DASHBOARD_INSCRICAO_EDITAR = '__ol-mirim.dashboard.inscricao.editar';
export const __DASHBOARD_RECIBO = '__ol-mirim.dashboard.recibo';
export const __DASHBOARD_RELATORIO = '__ol-mirim.dashboard.relatorio';
export const __DASHBOARD_SENHA = '__ol-mirim.dashboard.senha';
export const __DASHBOARD_VISUALIZAR = '__ol-mirim.dashboard.visualizar';
export const __DASHBOARD_DOWNLOAD = '__ol-mirim.dashboard.download';

export const __OBJETO_POSTAL_VISUALIZAR = '__ol-mirim.objeto-postal.visualizar';
export const __OBJETO_POSTAL_EXPORTAR = '__ol-mirim.objeto-postal.exportar';
export const __OBJETO_POSTAL_ERROS_VISUALIZAR = '__ol-mirim.objeto-postal-erros.visualizar';
export const __OBJETO_POSTAL_ERROS_EXPORTAR = '__ol-mirim.objeto-postal-erros.exportar';
export const __OBJETO_POSTAL_POR_DATA_VISUALIZAR = '__ol-mirim.objeto-postal-por-data.visualizar';
export const __OBJETO_POSTAL_POR_DATA_EXPORTAR = '__ol-mirim.objeto-postal-por-data.exportar';
export const __OBJETO_POSTAL_POR_STATUS_VISUALIZAR = '__ol-mirim.objeto-postal-por-status.visualizar';
export const __OBJETO_POSTAL_POR_STATUS_EXPORTAR = '__ol-mirim.objeto-postal-por-status.exportar';

export const __TAREFA_VISUALIZAR = '__ol-mirim.tarefa.visualizar';
export const __TAREFA_IMPORTACAO_OBJETOS_POSTAIS_INICIAR = '__ol-mirim.tarefa.importar-objeto-postal.iniciar';
export const __TAREFA_IMPORTACAO_OBJETOS_POSTAIS_ACOMPANHAR = '__ol-mirim.tarefa.importar-objeto-postal.acompanhar';
export const __TAREFA_ATUALIZAR_STATUS_SEDEX_INICIAR = '__ol-mirim.tarefa.atualizar-status-sedex.iniciar';
export const __TAREFA_ATUALIZAR_STATUS_SEDEX_ACOMPANHAR = '__ol-mirim.tarefa.atualizar-status-sedex.acompanhar';