import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { ObjetoPostalErroService } from '../../services/ObjetoPostal';
import { errors } from '../../services/API';

import { removePagingAndSorting } from '../../helper/PaginationHelper';
import { filterPersistence } from '../../helper/ParseStateHelper';

import ObjetoPostalErroFilter from '../../forms/ObjetoPostal/ObjetoPostalErroFilter';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import {
    verifyPagingAndSorting,
    PAGE,
    SIZE,
    ASC,
    TOTAL_ELEMENTS,
    TOTAL_PAGES,
} from '../../components/Table/Utils';

import { __OBJETO_POSTAL_ERROS_EXPORTAR } from '../../security/RoleConfiguration';
import AuthorizedElement from '../../security/AuthorizedElement';

import { Layout } from '../../layouts/private/Private';

export class ObjetoPostalErro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: PAGE,
            size: SIZE,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
            sort: [{ orderBy: 'numero', order: ASC }],
            defaultOrderBy: 'numero',
            filtered: false,
            filters: [],
            objetosPostaisErros: [],
            loading: true,
            history: {
                path: null,
                state: null
            }
        }
    }

    // FECHAR TOAST
    setToast = t => this.Toast = t;
    setObjetoPostalErroFilter = e => this.ObjetoPostalErroFilter = e;

    // EXPORTAÇÂO
    handleExportPlan = () => {
        this.ObjetoPostalErroFilter.setState({ filter: true, exportPlan: true, }, () => this.ObjetoPostalErroFilter.handleFilterApply());

        this.Toast.setState({
            message: {
                message: "A Planilha está sendo gerada para Exportação.",
                type: 'info',
                open: true,
                loading: true,
                autohide: false,
            }
        })
    }

    loadData = () => {
        this.setState({ history: filterPersistence(this.props.location) });

        let f = verifyPagingAndSorting(this.state);

        this.setState({
            filters: [...f],
            filtered: true,
            loading: true
        });

        ObjetoPostalErroService.filters(f)
            .then(res => {
                if (res.data.content) {
                    this.setState({
                        objetosPostaisErros: [...res.data.content],
                        page: res.data.number,
                        size: res.data.size,
                        totalElements: res.data.totalElements,
                        totalPages: res.data.totalPages,
                        loading: false,
                    });
                } else {
                    this.setState({
                        objetosPostaisErros: [],
                        page: res.data.number,
                        size: res.data.size,
                        totalElements: res.data.totalElements,
                        totalPages: res.data.totalPages,
                        loading: false,
                    });
                }
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loading: false }))
    }

    // FILTROS
    filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

    // PÁGINA
    handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

    // LINHAS POR PÁGINA
    handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

    // ORDENAÇÃO
    handleSort = (orderBy, order) => {
        if (this.state.sort.find(s => s.orderBy === orderBy)) {
            let newSort = this.state.sort.filter(s => s.orderBy !== orderBy);
            newSort.splice(this.state.sort.indexOf(this.state.sort.find(s => s.orderBy === orderBy)), 0, { orderBy: orderBy, order: order })
            this.setState({...this.state, sort: [...newSort], filtered: true });
        } else {
            this.setState({ ...this.state, sort: [...this.state.sort.filter(s => s.orderBy !== orderBy), { orderBy: orderBy, order: order }], filtered: true });
        }
    }
    handleClearSort = (orderBy) => {
        this.setState({ ...this.state, filtered: true, sort: orderBy ? [...this.state.sort.filter(s => s.orderBy !== orderBy)] : [{ orderBy: this.state.defaultOrderBy, order: ASC }] });
    };

    // TOTAL DE PAGINAS
    handleTotalPages = () => this.state.totalPages;

    // TOTAL DE ELEMENTOS
    handleTotalElements = () => this.state.totalElements;

    // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
    handleFilterPersistence = () => this.ObjetoPostalErroFilter ? this.ObjetoPostalErroFilter.state ? this.ObjetoPostalErroFilter.handleFilterPersistence(this.state.filters) : null : null;

    // COMPONENT DID MOUNT
    componentDidMount() { this.loadData(); }

    render() {
        const pageName = 'Erros de Importação de Objetos Postais';
        const links = [];

        const columns = [
            { label: 'Código Sedex', name: 'numero', func: null, key: true },
            { label: 'INEP', name: 'codigoMec', func: null, key: false },
            { label: 'Erro de Importação', name: 'erro', func: null, key: false, ordering: false },
            { label: 'Data de Envio', name: 'dataEnvioSedex', func: null, key: false, ordering: false },
            { label: 'Peso', name: 'peso', func: null, key: false, ordering: false },
            { label: 'Qtd Provas Mirim 1', name: 'qtdProvasMirim1', func: null, key: false, ordering: false },
            { label: 'Qtd Provas Mirim 2', name: 'qtdProvasMirim2', func: null, key: false, ordering: false },
        ];

        this.handleFilterPersistence();

        return (
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={pageName} />
                    </Grid>
                </Grid>
                <Title>{pageName}</Title>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ObjetoPostalErroFilter
                            parentRef={this.setObjetoPostalErroFilter}
                            handleFilterChange={this.filterData}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <AuthorizedElement roles={[__OBJETO_POSTAL_ERROS_EXPORTAR]}>
                                <ButtonExport
                                    title="Exportar Planilha"
                                    onClick={this.handleExportPlan}
                                />
                            </AuthorizedElement>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Table
                            columns={columns}
                            tableData={this.state.objetosPostaisErros}
                            actions={[]}
                            page={this.state.page}
                            handlePage={this.handlePage}
                            handleTotalPages={this.handleTotalPages}
                            handleTotalElements={this.handleTotalElements}
                            rowsPerPage={this.state.size}
                            handleRowsPerPage={this.handleRowsPerPage}
                            sort={this.state.sort}
                            handleSort={this.handleSort}
                            handleClearSort={this.handleClearSort}
                            loading={this.state.loading}
                        />
                    </Grid>
                </Grid>
            </Layout >
        )
    }
}

export default withRouter(ObjetoPostalErro);