import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import EscolaService from '../../services/Escola';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import { ButtonSecondary } from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';

import DadosResponsavel from '../../forms/Recibo/DadosResponsavel';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __RELATORIO_ESCOLA_EXPORTAR } from '../../security/RoleConfiguration';

import { locationEscolaParse } from '../../helper/ParseStateHelper';
import { download } from '../../helper/FileHelper';

import { Layout } from '../../layouts/private/Private';

export class Escola extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      id: null,
      history: {
        path: null,
        state: null,
      }
    }
  }  

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setDadosResponsavel = d => this.DadosResponsavel = d;

  // EXPORTAR PDF
  handleExportChange = () => {
    const f = [];
    f.push({ field: 'id', value: this.state.id });
    f.push({ field: 'exportarPDF', value: true });

    this.Toast.setState({
        message: {
          message: "O PDF está sendo gerado para Exportação.",
          type: 'success',
          open: true
        }
    })

    EscolaService.exportReportSchool(this.state.id, f)
      .then(res => download(res))
  }

  componentDidMount() {
    this.filterPersistence(this.props.location);

    const f = locationEscolaParse(this.props.location);
    
    this.setState({ id: f.find(f => f.field === 'id').value });

    EscolaService.filtersReportSchool(this.state.id, f)
      .then(res => {
        this.setState({ loading: false });

        this.setState({
          responsavel: {
            email: res.data.responsavel.email,
            cpf: res.data.responsavel.nmCpf,
            nome: res.data.responsavel.nome,
            telefones: res.data.responsavel.telefonesFormtados,
            cargo: res.data.responsavel.nmCargo
          }
        });
      })
      .catch(error => {
        const e = errors(error);        
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      })
  }

  filterPersistence = (parseLocation) => {
    if (parseLocation.state && parseLocation.state.history) {
      this.setState({
        history: {
          path: parseLocation.state.history.path,
          state: parseLocation.state.history.state
        }
      });
    }
  }

  handleBack = () => {
    this.props.history.push({
      pathname: `${this.state.history.path}`,
      state: {
        history: {
          state: this.state.history.state
        }
      }
    });
  }

  render () {
    const pageName = 'Relatório de Participação';
    const links = [];

    return (
      <Layout>
        <Toast parentRef={this.setToast} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>
        <Grid container spacing={3}>
          <AuthorizedElement roles={[__RELATORIO_ESCOLA_EXPORTAR]}>
            <ButtonExport
              title="Exportar PDF"
              onClick={this.handleExportChange}
            />
          </AuthorizedElement>
        </Grid>

        { this.state.loading ? 
          <Loading />
        :
          <>
            <DadosResponsavel responsavel={this.state.responsavel} />
            {/* TODO --- SEGUNDA FASE --- VER QUAL VAI SER DESSE RELATÓRIO */}
          </>
        }

        <Grid container spacing={3} alignItems='flex-end' style={{ marginTop: 16 }}>
          <Grid item sm={12} lg={12}>
            <ButtonSecondary
              startIcon={<ArrowBackIcon />}
              style={{ margin: 4 }}
              onClick={this.state.history.path ? () => this.handleBack() : this.props.history.goBack} 
              name={"Voltar"}
            />
          </Grid>   
        </Grid>
      </Layout>
    )
  }
}

export default withRouter(Escola);