import API from './API';

const path = `municipios`;

const UFService = {
    // LISTAR TODOS
    list: async () => await API.list(path),

    // LISTAR REGIÕES
    listRegions: async () => await API.list(`${path}/regioes`),

    // FILTROS
    filters: async filters => await API.filters(path, filters),
}

export default UFService;