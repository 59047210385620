import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import {
  isAfterDataProvaFase2,
  isPeriodoRegistroParticipacaoCadastroAlunos,
  isPeriodoInserirNotaFase2,
} from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DescriptionIcon from '@material-ui/icons/Description';

import AlertaEscola, { escolaComCadastroAlunoDisponivel } from './AlertaEscola';
import { TermoAutorizacao as DialogTermoAutorizacao } from './TermoAutorizacao';
import { Nota as DialogNota } from './Nota';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { ButtonPrimary } from '../../components/Button/Button';
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Table from '../../components/Table/Table';
import {
  verifyPagingAndSorting,
  PAGE,
  SIZE,
  ASC,
  TOTAL_ELEMENTS,
  TOTAL_PAGES,
} from '../../components/Table/Utils';

import { DialogRemove as DialogRemoverAluno } from '../../forms/Dialog/DialogRemove';
import AlunoFilter from '../../forms/Aluno/AlunoFilter';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';
import { ButtonAdd } from '../../forms/Buttons/ButtonsAdd';

import AlunoService from '../../services/Aluno';
import EscolaService from '../../services/Escola';
import { errors } from '../../services/API';

import { verifyPersistence, removePagingAndSorting } from '../../helper/PaginationHelper';
import { download } from '../../helper/FileHelper';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import AuthorizedElement from '../../security/AuthorizedElement';
import {
  __ALUNO_ADICIONAR,
  __ALUNO_EXPORTAR,
  __ALUNO_EDITAR,
  __ALUNO_REMOVER,
  __ALUNO_TERMO_ADICIONAR,
} from '../../security/RoleConfiguration';

import {
  patternAnoLetivo,
  patternNivel,
  patternSexo,
  patternTipoProva,
} from '../../variables/Enums/Aluno';

export class Aluno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: PAGE,
      size: SIZE,
      totalElements: TOTAL_ELEMENTS,
      totalPages: TOTAL_PAGES,
      sort: [
        { orderBy: 'inscricao.escola.cdMecEscola', order: ASC },
        { orderBy: 'anoLetivo', order: ASC },
        { orderBy: 'notaFase1', order: ASC },
      ],
      defaultOrderBy: ['inscricao.escola.cdMecEscola', 'anoLetivo', 'notaFase1'],
      filtered: false,
      filters: [],
      alunos: [],
      loading: true,
      loadingButtonSave: false,
      escola: this.props.school ?? null,
    }

    this.state = verifyPersistence(this.state, this.props.location);
  }

  // FECHAR TOAST
  setToast = t => this.Toast = t;
  setAlunoFilter = t => this.AlunoFilter = t;
  setDialogRemoverAluno = r => this.DialogRemoverAluno = r;
  setDialogTermoAutorizacao = a => this.DialogTermoAutorizacao = a;
  setDialogNota = n => this.DialogNota = n;

  // LABELS
  labelSemPermissao = "Você não tem permissão para fazer isso";
  labelForaPeriodoCadastroAluno = "Fora do Período de Gestão de Aluno";
  labelForaPeriodoInserirNotaFase2 = "Fora do Período de Inserção de Nota da 2ª Fase para o Aluno";

  labelEditar = id => !this.disabledEditar(id) ? isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) ? "Editar" : this.labelForaPeriodoCadastroAluno : this.labelSemPermissao;
  labelNotaFase2 = id => !this.disabledEditar(id) ? isPeriodoInserirNotaFase2(this.props.contexts) ? "Inserir Nota da 2ª Fase" : this.labelForaPeriodoInserirNotaFase2 : this.labelSemPermissao;
  labelExcluir = id => !this.disabledExcluir(id) ? isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) ? "Excluir" : this.labelForaPeriodoCadastroAluno : this.labelSemPermissao;
  labelTermo = id => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);
    return !this.disabledTermo(id) ? `${aluno.comTermoValido ? "Download de " : ""}Termo de Autorização` : this.labelSemPermissao;
  }

  // DISABLES
  disabledTermo = id => !AuthorizedFunction([__ALUNO_TERMO_ADICIONAR]);
  disabledEditar = id => AuthorizedFunction([__ALUNO_EDITAR]) ? !isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) : !AuthorizedFunction([__ALUNO_EDITAR]);
  disabledNotaFase2 = id => AuthorizedFunction([__ALUNO_EDITAR]) ? !isPeriodoInserirNotaFase2(this.props.contexts) : !AuthorizedFunction([__ALUNO_EDITAR]);
  disabledExcluir = id => AuthorizedFunction([__ALUNO_REMOVER]) ? !isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) : !AuthorizedFunction([__ALUNO_REMOVER]);

  loadData = async () => {
    this.props.loadContext();

    let filters = verifyPagingAndSorting(this.state);

    if (this.props.school) {
      filters = filters.filter(f => f.field !== 'cdMecEscola' && f.field !== 'nmEscola')
      filters.push({ field: 'cdMecEscola', value: this.props.school.cdMecEscola });
      filters.push({ field: 'nmEscola', value: this.props.school.nmEscola });
    }

    this.setState({
      filters: [...filters],
      filtered: true,
      loading: true
    });

    this.verificarDadosEscolaFiltrada(filters);

    await AlunoService.filters(filters)
      .then(res => {
        
        if (res.data.content) {
          this.setState({
            alunos: [...res.data.content],
            page: res.data.number,
            size: res.data.size,
            totalElements: res.data.totalElements,
            totalPages: res.data.totalPages,
            loading: false,
          });

          this.AlunoFilter.handleFilterPersistence(filters);
        } else {

          this.setState({
            alunos: [],
            page: PAGE,
            size: SIZE,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
            loading: false,
          });
        }
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      })
  }

  buscouPorEscola = filters => {
    return filters.find(f => f.field === "cdMecEscola")
  }

  verificarDadosEscolaFiltrada = filters => {
    const buscandoMec = this.buscouPorEscola(filters);

    if (buscandoMec) {
      if (this.state.escola && buscandoMec.value !== this.state.escola.cdMecEscola)
        this.setState({ escola: null });

      EscolaService.find(buscandoMec.value)
        .then(res => {
          const { inscricao } = res.data
          if (inscricao) {
            this.setState({
              escola: res.data,
            })
          }
        })
        .catch(error => {
          const e = errors(error);
          this.Toast.setState({
            message: {
              message: e.message,
              type: e.type,
              open: true
            }
          })
        })
    } else {
      this.setState({ escola: null });
    }
  }

  // EDIÇÃO
  handleEditClick = id => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);
    this.props.history.push({
      pathname: `/admin/${aluno.cdMecEscola}/aluno/${id}/editar`,
      state: {
        history: {
          path: this.props.school ? `/admin/inscricao/${this.state.escola.cdMecEscola}/fase-2` : `/admin/aluno`,
          state: this.state,
        }
      }
    });
  }

  // ABRIR MODAL DE CONFIRMAÇÃO DE REMOÇÃO
  handleExcluir = id => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);
    this.DialogRemoverAluno.setState({
      dialog: {
        open: true,
        title: `Deseja Remover o Aluno ${aluno.nmAluno} ?`,
        text: `O Aluno não poderá ser recuperado depois de ser removido.`,
        id: id,
        loading: false,
      }
    });
  }

  // REMOÇÃO DE ALUNO
  handleRemoverAlunoConfirm = (id) => {
    this.DialogRemoverAluno.loading();

    AlunoService.remove(id)
      .then(res => {
        this.Toast.setState({
          message: {
            message: "O Aluno foi Excluído com Sucesso.",
            type: 'success',
            open: true
          }
        })
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => {
        this.DialogRemoverAluno.close();
        this.loadData();
      });
  }

  // EXPORTAÇÂO
  handleExportPlan = () => {
    this.AlunoFilter.setState({ filter: true, exportPlan: true, }, () => this.AlunoFilter.handleFilterApply());

    this.Toast.setState({
      message: {
        message: "A Planilha está sendo gerada para Exportação.",
        type: 'info',
        open: true,
        loading: true,
        autohide: false,
      }
    })
  }

  // ABRIR MODAL DE TERMO DE AUTORIZAÇÃO
  handleTermoClick = id => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);
    this.DialogTermoAutorizacao.openModal(id, aluno.nmAluno);
  }

  buttonTermo = (id) => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);
    
    if (aluno.comTermoValido) 
      return <DescriptionIcon />

    return (
      <ButtonPrimary
        onClick={() => this.handleTermoClick(aluno.cdAluno)}
        name={"Termo de Autorização"}
        disabled={this.disabledTermo(id)}
      />
    )
  }

  buttonNota = (id) => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);

    return (
      <ButtonPrimary
        onClick={() => this.handleNotaFase2Click(aluno.cdAluno)}
        name={`${aluno.notaFase2 ? "Editar" : "Informar" } Nota da Fase 2`}
        disabled={this.disabledNotaFase2(id)}
      />
    )
  }

  handleTermoDownloadClick = (id) => {
    const aluno = this.state.alunos.find(s => s.cdAluno === id);
    if (aluno.comTermoValido) {
      this.Toast.setState({
        message: {
          message: "Em Instantes o Download Iniciará Automáticamente",
          type: "info",
          open: true
        }
      });

      AlunoService.downloadTermoValidoAluno(id)
        .then(res => download(res))
        .catch(error => {
          const e = errors(error);
          this.Toast.setState({
            message: {
              message: e.message,
              type: e.type,
              open: true
            }
          })
        });
    }
  }

  patternNotaFase2 = (nota) => isAfterDataProvaFase2(this.props.contexts) ? nota ?? "Nota Não Informada" : "Prova Não Realizada";

  handleNotaFase2Click = id => this.DialogNota.openModal(this.state.alunos.find(s => s.cdAluno === id));

  // FILTROS
  filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

  // PÁGINA
  handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

  // LINHAS POR PÁGINA
  handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

  // ORDENAÇÃO
  verifyOrderBy = (orderBy) => orderBy === 'cdMecEscola' ? 'inscricao.escola.cdMecEscola' : orderBy;
  handleSort = (orderBy, order) => {
    if (this.state.sort.find(s => s.orderBy === this.verifyOrderBy(orderBy))) {
      let newSort = this.state.sort.filter(s => s.orderBy !== this.verifyOrderBy(orderBy));
      newSort.splice(this.state.sort.indexOf(this.state.sort.find(s => s.orderBy === this.verifyOrderBy(orderBy))), 0, { orderBy: this.verifyOrderBy(orderBy), order: order })
      this.setState({...this.state, sort: [...newSort], filtered: true });
    } else {
      this.setState({ ...this.state, sort: [...this.state.sort.filter(s => s.orderBy !== this.verifyOrderBy(orderBy)), { orderBy: this.verifyOrderBy(orderBy), order: order }], filtered: true });
    }
  }
  handleClearSort = (orderBy) => {
    this.setState({ ...this.state, filtered: true, sort: orderBy ? [...this.state.sort.filter(s => s.orderBy !== this.verifyOrderBy(orderBy))] : [...this.state.defaultOrderBy.map(d => { return { orderBy: this.verifyOrderBy(d), order: ASC }} )]});
  };

  // TOTAL DE PAGINAS
  handleTotalPages = () => this.state.totalPages;

  // TOTAL DE ELEMENTOS
  handleTotalElements = () => this.state.totalElements;

  componentDidMount() { this.loadData(); }

  render() {
    const pageName = 'Listagem de Alunos';
    const links = [];

    const columns = [
      { label: '#', name: 'cdAluno', func: null, key: true },
      { label: 'INEP da Escola', name: 'cdMecEscola', func: null, key: false, ordering: false },
      { label: 'Nome', name: 'nmAluno', func: null, key: false },
      { label: 'Sexo', name: 'sexo', func: patternSexo, key: false },
      { label: 'Data de Nascimento', name: 'dataNascimento', func: null, key: false },
      { label: 'Nível', name: 'nivel', func: patternNivel, key: false, ordering: false },
      { label: 'Ano Letivo', name: 'anoLetivo', func: patternAnoLetivo, key: false},
      { label: 'Nota - Fase 1', name: 'notaFase1', func: null, key: false },
      { label: 'Tipo de Prova', name: 'tipoProva', func: patternTipoProva, key: false },
      { label: 'Nota - Fase 2', name: 'notaFase2', func: this.patternNotaFase2, key: false, ordering: false },
    ];

    const actions = [
      {
        name: this.labelEditar,
        func: this.handleEditClick,
        icon: <EditOutlinedIcon />,
        disabled: this.disabledEditar,
      },
      {
        name: this.labelNotaFase2,
        func: this.handleNotaFase2Click,
        icon: this.buttonNota,
        disabled: this.disabledNotaFase2,
      },
      {
        name: this.labelTermo,
        func: this.handleTermoDownloadClick,
        icon: this.buttonTermo,
        disabled: this.disabledTermo,
      },
      {
        name: this.labelExcluir,
        func: this.handleExcluir,
        icon: <DeleteOutlinedIcon />,
        disabled: this.disabledExcluir,
        color: "secondary"
      },
    ];

    return (
      <>
        <Toast parentRef={this.setToast} />
        {!this.props.school &&
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs links={links} active={pageName} />
            </Grid>
          </Grid>
        }
        <Title>{pageName}</Title>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AlunoFilter
              parentRef={this.setAlunoFilter}
              handleFilterChange={this.filterData}
              disabledFilterSchool={this.props.school ? true : false}
            />
          </Grid>
          <Grid item xs={12}>
            {this.state.escola &&
              <AlertaEscola
                escola={this.state.escola}
              />
            }
            <Grid container spacing={3}>
              <AuthorizedElement roles={[__ALUNO_ADICIONAR]}>
                {isPeriodoRegistroParticipacaoCadastroAlunos(this.props.contexts) && escolaComCadastroAlunoDisponivel(this.state.escola) &&
                  <ButtonAdd
                    to={{
                      pathname: `/admin/${this.state.escola.cdMecEscola}/aluno/adicionar`,
                      state: {
                        history: {
                          path: this.props.school ? `/admin/inscricao/${this.state.escola.cdMecEscola}/fase-2` : '/admin/aluno',
                          state: this.state,
                        }
                      }
                    }}
                    title="Adicionar Aluno"
                  />
                }
              </AuthorizedElement>
              <AuthorizedElement roles={[__ALUNO_EXPORTAR]}>
                {this.state.filters.length > 3 &&
                  <ButtonExport
                    title="Exportar Planilha"
                    onClick={this.handleExportPlan}
                  />
                }
              </AuthorizedElement>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Table
              columns={columns}
              tableData={this.state.alunos}
              actions={actions}
              page={this.state.page}
              handlePage={this.handlePage}
              handleTotalPages={this.handleTotalPages}
              handleTotalElements={this.handleTotalElements}
              rowsPerPage={this.state.size}
              handleRowsPerPage={this.handleRowsPerPage}
              sort={this.state.sort}
              handleSort={this.handleSort}
              handleClearSort={this.handleClearSort}
              loading={this.state.loading}
            />
          </Grid>
        </Grid>

        {AuthorizedFunction([__ALUNO_TERMO_ADICIONAR]) &&
          <DialogTermoAutorizacao
            parentRef={this.setDialogTermoAutorizacao}
            handleClose={this.loadData}
          />
        }

        {AuthorizedFunction([__ALUNO_REMOVER]) &&
          <DialogRemoverAluno
            parentRef={this.setDialogRemoverAluno}
            handleConfirm={this.handleRemoverAlunoConfirm}
          />
        }

        {isPeriodoInserirNotaFase2(this.props.contexts) &&
          <DialogNota
            parentRef={this.setDialogNota}
            handleClose={this.loadData}
          />
        }
      </>
    )
  }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Aluno));