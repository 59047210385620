import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import AlunoService from '../../services/Aluno';

import Filters from '../../components/Filters/Filters';
import Text from '../../components/Inputs/Text/Text';
import SelectOption from '../../components/Inputs/Select/Select';

import { EscolaFilterFields as Escola } from '../Escola/EscolaFilterFields';
import Endereco from '../Endereco/EnderecoFilter';
import DependenciaAdministrativa from '../DependenciaAdministrativa/DependenciaAdministrativaFilter';

import {
    optionsSexo,
    optionsTipoProva,
    optionsNivel,
    optionsAnoLetivo,
    optionsTermo
} from '../../variables/Enums/Aluno';

import { download } from '../../helper/FileHelper';
import { removePagingAndSorting } from '../../helper/PaginationHelper';

export class AlunoFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
                nmAluno: '',
                sexo: '',
                tipoProva: '',
                nivel: '',
                anoLetivo: '',
                statusTermo: ''
            },
            exportPlan: false,
            count: 0,
        };
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            filters: { ...this.state.filters, [e.target.name]: e.target.value }
        });
    };

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES TIPO FORM
    setEscola = form => this.Escola = form;
    setEndereco = form => this.Endereco = form;
    setDependenciaAdministrativa = form => this.DependenciaAdministrativa = form;

    // APLICAR PERSISTENCIA
    handleFilterPersistence = (filters) => {
        filters = removePagingAndSorting(filters);

        this.setState({
            filters: {
                nmAluno: filters.find(f => f.field === 'nmAluno') ? filters.find(f => f.field === 'nmAluno').value : "",
                sexo: filters.find(f => f.field === 'sexo') ? filters.find(f => f.field === 'sexo').value : "",
                tipoProva: filters.find(f => f.field === 'tipoProva') ? filters.find(f => f.field === 'tipoProva').value : "",
                nivel: filters.find(f => f.field === 'nivel') ? filters.find(f => f.field === 'nivel').value : "",
                anoLetivo: filters.find(f => f.field === 'anoLetivo') ? filters.find(f => f.field === 'anoLetivo').value : "",
                statusTermo: filters.find(f => f.field === 'statusTermo') ? filters.find(f => f.field === 'statusTermo').value : ""
            },
            count: filters.reduce((total, f) => f.value !== '' ? total + 1 : total, 0),
        })

        if (!this.props.disabledFilterSchool) {
            this.Escola.setState({
                filters: {
                    nameSchool: filters.find(f => f.field === 'nmEscola') ? filters.find(f => f.field === 'nmEscola').value : "",
                    inepSchool: filters.find(f => f.field === 'cdMecEscola') ? filters.find(f => f.field === 'cdMecEscola').value : "",
                }
            });

            this.DependenciaAdministrativa.setState({
                filters: {
                    adminDep: filters.find(f =>  f.field === 'nmDependenciaAdministrativa') ? filters.find(f =>  f.field === 'nmDependenciaAdministrativa').value : "",
                }
            })
        
            this.Endereco.setState({
                filters: {
                    UF: filters.find(f =>  f.field === 'cdUf') ? filters.find(f =>  f.field === 'cdUf').value : "",
                    city: filters.find(f =>  f.field === 'cdMunicipio') ? filters.find(f =>  f.field === 'cdMunicipio').value : "",
                    region: filters.find(f =>  f.field === 'cdRegiao') ? filters.find(f =>  f.field === 'cdRegiao').value : "",
                }
            })
        }
    }

    // FILTROS
    handleFilterApply = () => {
        const f = [];
        const { filters, exportPlan } = this.state;
        const filtersEscola = !this.props.disabledFilterSchool ? this.Escola.state.filters : null;
        const filtersDepAdmin = !this.props.disabledFilterSchool ? this.DependenciaAdministrativa.state.filters : null;
        const filtersEndereco = !this.props.disabledFilterSchool ? this.Endereco.state.filters : null;

        if (filters.nmAluno !== '') f.push({ field: 'nmAluno', value: filters.nmAluno });
        if (filters.sexo !== '') f.push({ field: 'sexo', value: filters.sexo });
        if (filters.tipoProva !== '') f.push({ field: 'tipoProva', value: filters.tipoProva });
        if (filters.nivel) f.push({ field: 'nivel', value: filters.nivel });
        if (filters.anoLetivo) f.push({ field: 'anoLetivo', value: filters.anoLetivo });
        if (filters.statusTermo) f.push({ field: 'statusTermo', value: filters.statusTermo });

        if (!this.props.disabledFilterSchool) {
            if (filtersEscola.nameSchool) f.push({ field: 'nmEscola', value: filtersEscola.nameSchool })
            if (filtersEscola.inepSchool) f.push({ field: 'cdMecEscola', value: filtersEscola.inepSchool })

            if (filtersDepAdmin.adminDep) f.push({ field: 'nmDependenciaAdministrativa', value: filtersDepAdmin.adminDep })
            
            if (filtersEndereco.UF) f.push({ field: 'cdUf', value: filtersEndereco.UF })
            if (filtersEndereco.city) f.push({ field: 'cdMunicipio', value: filtersEndereco.city })
            if (filtersEndereco.region) f.push({ field: 'cdRegiao', value: filtersEndereco.region })
        }

        if (exportPlan) {
            f.push({ field: 'exportarPlanilha', value: exportPlan });

            AlunoService.export(f)
                .then(res => download(res))
                .finally(() => this.setState({ exportPlan: false }));
        } else {
            this.props.handleFilterChange(f);
        };
    }

    // LIMPAR FILTROS
    handleFilterClear = () => {
        this.setState({
            filters: {
                nmAluno: '',
                sexo: '',
                tipoProva: '',
                nivel: '',
                anoLetivo: '',
                statusTermo: ''
            },
            exportPlan: false,
            count: 0,
        });

        if (!this.props.disabledFilterSchool) {
            this.Escola.setState({
                filters: {
                    nameSchool: '',
                    inepSchool: '',
                }
            });

            this.DependenciaAdministrativa.setState({
                filters: {
                    adminDep: '',
                }
            })
            
            this.Endereco.setState({
                filters: {
                    city: '',
                    UF: '',
                    region: '',
                }
            })
        }

        this.props.handleFilterChange([], false);
    }

    // CONTAGEM DE FILTROS ATIVOS
    handleCountFilters = () => this.state.count;

    render() {
        return (
            <Filters
                handleFilter={this.handleFilterApply}
                handleFilterClear={this.handleFilterClear}
                countFilters={this.handleCountFilters}
            >
                <Grid item container spacing={3}>
                    <Escola
                        parentRef={this.setEscola}
                        disabledEmail={true}
                        disabledChangeAll={this.props.disabledFilterSchool}
                    />                    
                </Grid>                
                {!this.props.disabledFilterSchool &&
                    <Grid item container spacing={3}>
                        <DependenciaAdministrativa 
                            parentRef={this.setDependenciaAdministrativa} 
                            withFederal={true}
                        />
                        <Endereco 
                            parentRef={this.setEndereco}
                            withRegion={true}
                        />
                    </Grid>
                }
                <Grid item container spacing={4}>
                    <Grid item sm={12} lg={4}>
                        <Text
                            label="Nome do Aluno"
                            name='nmAluno'
                            value={this.state.filters.nmAluno}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Sexo"
                            name='sexo'
                            value={this.state.filters.sexo}
                            options={optionsSexo}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Nivel"
                            name='nivel'
                            value={this.state.filters.nivel}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsNivel}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Ano Escolar"
                            name='anoLetivo'
                            value={this.state.filters.anoLetivo}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsAnoLetivo}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Tipo de Prova"
                            name='tipoProva'
                            value={this.state.filters.tipoProva}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsTipoProva}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={4}>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Status Termo"
                            name='statusTermo'
                            value={this.state.filters.statusTermo}
                            options={optionsTermo}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                </Grid>
            </Filters>
        )
    }
}

export default AlunoFilter;