import Dashboard from '../../views/Dashboard/Dashboard';
import Contexto from '../../views/Contexto/Contexto';
import Escola from '../../views/Escola/Escola';
import Aluno from '../../views/Aluno/List';
import Email from '../../views/Email/Email';
import Arquivo from '../../views/Arquivo/Arquivo';
import ObjetoPostal from '../../views/ObjetoPostal/ObjetoPostal';
import ObjetoPostalErro from '../../views/ObjetoPostal/ObjetoPostalErro';
import ObjetoPostalPorData from '../../views/ObjetoPostal/ObjetoPostalPorData';
import ObjetoPostalPorStatus from '../../views/ObjetoPostal/ObjetoPostalPorStatus';
import Tarefa from '../../views/Tarefa/Tarefa';
import RelatorioEscolas from '../../views/Relatorio/Escolas';
import RelatorioDependenciasAdministrativas from '../../views/Relatorio/DependenciasAdministrativas';
import RelatorioMunicipios from '../../views/Relatorio/Municipios';
import RelatorioUfs from '../../views/Relatorio/Ufs';
import RelatorioInscricoes from '../../views/Relatorio/QuantitativoInscricoes';
import RelatorioProvasEspeciais from '../../views/Relatorio/ProvasEspeciais';

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import AccessibleForwardOutlinedIcon from '@material-ui/icons/AccessibleForwardOutlined';

import {
    __ADMINISTRADOR,
    __CENTRAL,
    __DASHBOARD_VISUALIZAR,
    __CONTEXTO_VISUALIZAR_VALOR,
    __EMAIL_VISUALIZAR,
    __ESCOLA_VISUALIZAR,
    __ARQUIVO_VISUALIZAR,
    __OBJETO_POSTAL_VISUALIZAR,
    __OBJETO_POSTAL_ERROS_VISUALIZAR,
    __OBJETO_POSTAL_POR_DATA_VISUALIZAR,
    __OBJETO_POSTAL_POR_STATUS_VISUALIZAR,
    __TAREFA_VISUALIZAR,
    __RELATORIO_ESCOLAS_VISUALIZAR,
    __RELATORIO_DEPENDENCIAS_ADMINISTRATIVAS_VISUALIZAR, 
    __RELATORIO_MUNICIPIOS_VISUALIZAR, 
    __RELATORIO_UFS_VISUALIZAR,
    __RELATORIO_INSCRICOES_VISUALIZAR,
    __RELATORIO_PROVAS_ESPECIAIS_VISUALIZAR,
} from '../../security/RoleConfiguration';

import { Layout } from '../../layouts/private/Private';

export const path = '/admin';

export const mainPage = '/inicio';

export const dashboardMenuList = [
    {
        category: '',
        items: [
            {
                path: `${path}/inicio`,
                name: 'Início',
                icon: HomeOutlinedIcon,
                component: Dashboard,
                collapsed: false,
                submenus: [],
                roles: [ __DASHBOARD_VISUALIZAR ],
            },
            {
                path: `${path}/inicial`,
                name: 'Início',
                icon: HomeOutlinedIcon,
                component: Layout,
                collapsed: false,
                submenus: [],
                roles: [ __ADMINISTRADOR, __CENTRAL],
            },
        ]
    },
    {
        category: '',
        items: [
            {
                path: `${path}/contexto`,
                name: 'Contexto',
                icon: SettingsOutlinedIcon,
                component: Contexto,
                collapsed: false,
                submenus: [],
                roles: [ __CONTEXTO_VISUALIZAR_VALOR ],
            },
        ],
    },
    {
        category: '',
        items: [
            {
                path: `${path}/escola`,
                name: 'Escolas',
                icon: SchoolOutlinedIcon,
                component: Escola,
                collapsed: false,
                submenus: [],
                roles: [ __ESCOLA_VISUALIZAR ],
            },
            {
                path: `${path}/aluno`,
                name: 'Alunos',
                icon: FaceOutlinedIcon,
                component: Aluno,
                collapsed: false,
                submenus: [],
                roles: [ __ADMINISTRADOR, __CENTRAL ],
            },
            {
                path: `${path}/emails`,
                name: 'Emails',
                icon: EmailOutlinedIcon,
                component: Email,
                collapsed: false,
                submenus: [],
                roles: [ __EMAIL_VISUALIZAR ]
            },
        ],
    },
    {
        category: '',
        items: [
            {
                path: `${path}/arquivo`,
                name: 'Arquivos',
                icon: CloudDownloadOutlinedIcon,
                component: Arquivo,
                collapsed: false,
                submenus: [],
                roles: [ __ARQUIVO_VISUALIZAR ],
            },
            {
                path: `${path}/`,
                name: 'Materiais',
                icon: LocalShippingOutlinedIcon,
                component: null,
                collapsed: true,
                submenus: [
                    {
                        path: `${path}/objetosPostais`,
                        name: 'Objetos Postais',
                        icon: MarkunreadMailboxOutlinedIcon,
                        component: ObjetoPostal,
                        roles: [ __OBJETO_POSTAL_VISUALIZAR ],
                    },                     
                    {
                        path: `${path}/objetosPostais/data`,
                        name: 'Objetos Postais Por Data',
                        icon: DateRangeOutlinedIcon,
                        component: ObjetoPostalPorData,
                        roles: [ __OBJETO_POSTAL_POR_DATA_VISUALIZAR ],
                    },
                    {
                        path: `${path}/objetosPostais/status`,
                        name: 'Objetos Postais Por Status',
                        icon: AccountTreeOutlinedIcon,
                        component: ObjetoPostalPorStatus,
                        roles: [ __OBJETO_POSTAL_POR_STATUS_VISUALIZAR ],
                    },
                    {
                        path: `${path}/objetosPostais/erros`,
                        name: 'Erros de Importação',
                        icon: ReportProblemOutlinedIcon,
                        component: ObjetoPostalErro,
                        roles: [ __OBJETO_POSTAL_ERROS_VISUALIZAR ],
                    },
                ],
                roles: [
                    __OBJETO_POSTAL_VISUALIZAR,
                    __OBJETO_POSTAL_POR_DATA_VISUALIZAR,
                    __OBJETO_POSTAL_POR_STATUS_VISUALIZAR,
                    __OBJETO_POSTAL_ERROS_VISUALIZAR,
                ],
            },
            {
                path: `${path}/tarefas`,
                name: 'Tarefas',
                icon: ScheduleOutlinedIcon,
                component: Tarefa,
                collapsed: false,
                submenus: [],
                roles: [ __TAREFA_VISUALIZAR ],
            },
        ],
    },
    {
        category: '',
        items: [
            {
                path: `${path}/`,
                name: 'Relatórios Gerais',
                icon: DescriptionOutlinedIcon,
                component: null,
                collapsed: true,
                submenus: [
                    {
                        path: `${path}/relatorio/inscricoes`,
                        name: 'Inscrições Por Data',
                        icon: DateRangeOutlinedIcon,
                        component: RelatorioInscricoes,
                        roles: [ __RELATORIO_INSCRICOES_VISUALIZAR ],
                    }, 
                    {
                        path: `${path}/relatorio/escolas`,
                        name: 'Escolas Inscritas',
                        icon: SchoolOutlinedIcon,
                        component: RelatorioEscolas,
                        roles: [ __RELATORIO_ESCOLAS_VISUALIZAR ],
                    },                               
                    {
                        path: `${path}/relatorio/dependeciasAdministrativas`,
                        name: 'Dep. Administrativa',
                        icon: LocalOfferOutlinedIcon,
                        component: RelatorioDependenciasAdministrativas,
                        roles: [ __RELATORIO_DEPENDENCIAS_ADMINISTRATIVAS_VISUALIZAR ],
                    },
                    {
                        path: `${path}/relatorio/municipios`,
                        name: 'Municípios',
                        icon: PlaceOutlinedIcon,
                        component: RelatorioMunicipios,
                        roles: [ __RELATORIO_MUNICIPIOS_VISUALIZAR ],
                    },
                    {
                        path: `${path}/relatorio/ufs`,
                        name: "UF's",
                        icon: MapOutlinedIcon,
                        component: RelatorioUfs,
                        roles: [ __RELATORIO_UFS_VISUALIZAR ],
                    },       
                    {
                        path: `${path}/relatorio/provasEspeciais`,
                        name: 'Provas Especiais',
                        icon: AccessibleForwardOutlinedIcon,
                        component: RelatorioProvasEspeciais,
                        roles: [ __RELATORIO_PROVAS_ESPECIAIS_VISUALIZAR ],
                    },               
                ],
                roles: [
                    __RELATORIO_INSCRICOES_VISUALIZAR,
                    __RELATORIO_ESCOLAS_VISUALIZAR,
                    __RELATORIO_DEPENDENCIAS_ADMINISTRATIVAS_VISUALIZAR, 
                    __RELATORIO_MUNICIPIOS_VISUALIZAR, 
                    __RELATORIO_UFS_VISUALIZAR,
                    __RELATORIO_PROVAS_ESPECIAIS_VISUALIZAR, 
                ],
            },
        ],
    },
];

export default dashboardMenuList;