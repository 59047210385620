import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import PublishIcon from '@material-ui/icons/Publish';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { 
    isBlank, 
    isBlankHelperText,
    isSizeInvalid,
    isSizeInvalidHelperText,
} from '../../../helper/ValidationHelper';
import { sizeFiles } from '../../../helper/FileHelper';

export class File extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            file: null,
            errors: {
                file: false,
            },
            helpers: {
                file: null,
            }
        }
        this.isValid = this.isValid.bind(this);
    }

    // INPUT
    handleChange = e => this.setState({ 
        [e.target.name]: e.target.files[0], 
        errors: { 
            [e.target.name]: false 
        },
        helpers: { 
            [e.target.name]: false 
        },
    });

    // VALIDAÇÕES
    isValid = () => {
        if ( isBlank(this.state.file) || isSizeInvalid(this.state.file.size) ) {
            this.setState({ 
                errors: {
                    file: isBlank(this.state.file) || isSizeInvalid(this.state.file.size) ? true : false,
                },
                helpers: {
                    file: isBlank(this.state.file) ? isBlankHelperText() : isSizeInvalid(this.state.file.size) ? isSizeInvalidHelperText() : null,
                },  
            });
            return false;
        }
        return true;
    }

    render () {
        return (
            <Grid item sm={12} lg={12}>
                <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    startIcon={<PublishIcon />}
                    style={{ margin: 4 }}
                >
                    Selecionar Arquivo <sup style={{ marginLeft: 8 }}>*</sup>
                    <input
                        required
                        type="file"
                        name="file"
                        accept=".pdf, .zip, .rar, .jpg, .jpeg, .png"
                        style={{ display: "none" }}
                        onChange={this.handleChange}
                    />
                </Button>
                
                {this.state.file ?
                    this.state.errors.file ?
                        <>
                            <Typography variant="overline" display="inline" style={{ margin: 16 }} gutterBottom >{this.state.file.name} - {sizeFiles(this.state.file.size)}</Typography> 
                            <Typography variant="overline" display="inline" style={{ margin: 16, color: '#f34539', fontWeight: "bold" }} gutterBottom >{this.state.helpers.file}</Typography> 
                        </>
                    :
                        <Typography variant="overline" display="inline" style={{ margin: 16 }} gutterBottom >{this.state.file.name} - {sizeFiles(this.state.file.size)}</Typography> 
                : this.state.errors.file ?
                    <Typography variant="overline" display="inline" style={{ margin: 16, color: '#f34539', fontWeight: "bold" }} gutterBottom >{this.state.helpers.file}</Typography> 
                : 
                    <Typography variant="overline" display="inline" style={{ margin: 16, fontWeight: "bold" }} gutterBottom >Tamanho máximo permitido de 10mb.</Typography> 
                }
            </Grid>
        )
    }
}

export default File;