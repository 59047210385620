import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterDataProvaFase1 } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';

import { evasaoLimiterMask } from '../../helper/MaskHelper';

export class DadosUfs extends Component {
    constructor (props) {
        super(props)
        props.parentRef(this);
        this.state = {
            ufs: [],
        }
    }

    componentDidMount () { this.props.loadContext() }

    // SOMA DE UF'S
    sumUfs = () => this.state.ufs.length;

    // SOMA GERAL PARA ESCOLAS INSCRITAS
    sumEscolas = () => this.state.ufs.reduce((total, uf) => total + uf.qtdEscolasTotal, 0);
    sumEscolasInscritas = () => this.state.ufs.reduce((total, uf) => total + uf.qtdEscolasInscritasTotal, 0);

    sumMunicipios = () => this.state.ufs.reduce((total, uf) => total + uf.qtdMunicipiosTotal, 0);
    sumMunicipiosInscritos = () => this.state.ufs.reduce((total, uf) => total + uf.qtdMunicipiosInscritosTotal, 0);

    // SOMA GERAL PARA ALUNOS PREVISTOS
    sumAlunosPrevistos = () => this.state.ufs.reduce((total, uf) => total + uf.qtdAlunosPrevistosTotal, 0);

    // SOMA GERAL PARA ALUNOS PREVISTOS MIRIM 1
    sumAlunosPrevistosMirim1 = () => this.state.ufs.reduce((total, uf) => total + uf.qtdAlunosPrevistosMirim1, 0);

    // SOMA GERAL PARA ALUNOS PREVISTOS MIRIM 2
    sumAlunosPrevistosMirim2 = () => this.state.ufs.reduce((total, uf) => total + uf.qtdAlunosPrevistosMirim2, 0);

    // SOMA GERAL PARA ALUNOS PARTICIPANTES
    sumAlunosParticipantes = () => this.state.ufs.reduce((total, uf) => total + uf.qtdAlunosParticipantesTotal, 0);

    sumAlunosInscritosFase2 = () => this.state.ufs.reduce((total, uf) => total + uf.qtdAlunosInscritosFase2, 0);
    sumAlunosInscritosFase2Mirim1 = () => this.state.ufs.reduce((total, uf) => total + uf.qtdAlunosInscritosFase2Mirim1, 0);
    sumAlunosInscritosFase2Mirim2 = () => this.state.ufs.reduce((total, uf) => total + uf.qtdAlunosInscritosFase2Mirim2, 0);
    
    sumAlunosParticipantesFase2 = () => this.state.ufs.reduce((total, uf) => total + uf.qtdAlunosParticipantesFase2, 0);
    sumAlunosParticipantesFase2Mirim1 = () => this.state.ufs.reduce((total, uf) => total + uf.qtdAlunosParticipantesFase2Mirim1, 0);
    sumAlunosParticipantesFase2Mirim2 = () => this.state.ufs.reduce((total, uf) => total + uf.qtdAlunosParticipantesFase2Mirim2, 0);

    // CALCULAR EVASÃO
    calcEvasao = () => 100 - ((this.sumAlunosParticipantes() * 100) / this.sumAlunosPrevistos());

    render () {
        const columns = [
            { label: 'UF', name: 'nmUf', func: null, key: false, width: '6', },
            { label: 'Total de Escolas', name: 'qtdEscolasTotal', func: null, key: false, width: '6', },
            { label: 'Total de Escolas Inscritas', name: 'qtdEscolasInscritasTotal', func: null, key: false, width: '6', },
            { label: 'Total de Municipios', name: 'qtdMunicipiosTotal', func: null, key: false, width: '6', },
            { label: 'Total de Municipios Inscritos', name: 'qtdMunicipiosInscritosTotal', func: null, key: false, width: '6', },
            { label: 'Fase 1 - Mirim 1 - Alunos Inscritos', name: 'qtdAlunosPrevistosMirim1', func: null, key: false, width: '6', },
            { label: 'Fase 1 - Mirim 2 - Alunos Inscritos', name: 'qtdAlunosPrevistosMirim2', func: null, key: false, width: '6', },
            { label: 'Fase 1 - Total de Alunos Inscritos', name: 'qtdAlunosPrevistosTotal', func: null, key: false, width: '6', },
            { label: 'Fase 1 - Total de Alunos Participantes', name: 'qtdAlunosParticipantesTotal', func: null, key: false, width: '6', },
            { label: 'Fase 1 - Evasão Total', name: 'evasaoTotal', func: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask : () => "Prova Não Realizada", key: false, width: '6', },
            { label: 'Fase 2 - Mirim 1 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim1', func: null, key: false, width: '6', },
            { label: 'Fase 2 - Mirim 2 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim2', func: null, key: false, width: '6', },
            { label: 'Fase 2 - Total de Alunos Inscritos', name: 'qtdAlunosInscritosFase2', func: null, key: false, width: '6', },
            { label: 'Fase 2 - Mirim 1 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim1', func: null, key: false, width: '6', },
            { label: 'Fase 2 - Mirim 2 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim2', func: null, key: false, width: '6', },
            { label: 'Fase 2 - Total de Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2', func: null, key: false, width: '6', },
        ];
        
        const actions = [];

        const totals = [
            { column: 0, value: this.sumUfs(), width: '6', },
            { column: 1, value: this.sumEscolas(), width: '6', },
            { column: 2, value: this.sumEscolasInscritas(), width: '6', },
            { column: 3, value: this.sumMunicipios(), width: '6', },
            { column: 4, value: this.sumMunicipiosInscritos(), width: '6', },
            { column: 5, value: this.sumAlunosPrevistosMirim1(), width: '6', },
            { column: 6, value: this.sumAlunosPrevistosMirim2(), width: '6', },
            { column: 7, value: this.sumAlunosPrevistos(), width: '6', },
            { column: 8, value: this.sumAlunosParticipantes(), width: '6', },
            { column: 9, value: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask(this.calcEvasao()) : () => "Prova Não Realizada", width: '6', },
            { column: 10, value: this.sumAlunosInscritosFase2Mirim1(), width: '6', },
            { column: 11, value: this.sumAlunosInscritosFase2Mirim2(), width: '6', },
            { column: 12, value: this.sumAlunosInscritosFase2(), width: '6', },
            { column: 13, value: this.sumAlunosParticipantesFase2Mirim1(), width: '6', },
            { column: 14, value: this.sumAlunosParticipantesFase2Mirim2(), width: '6', },
            { column: 15, value: this.sumAlunosParticipantesFase2(), width: '6', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.state.ufs}
                        actions={actions}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} />
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(DadosUfs);