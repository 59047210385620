import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Text from '../../components/Inputs/Text/Text';
import Boolean from '../../components/Inputs/Boolean/Boolean';

export class ResponsavelInscricaoFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
                nameResponsible: '',
                emailResponsible: '',
                withResponsible: '' 
            }
        }
    }

    // INPUTS
    handleChange = e => {
        this.setState({ 
            filters: {...this.state.filters, [e.target.name]: e.target.value, } 
        })
    }

    render () {
        return (
            <>
                <Grid item sm={12} lg={4}>
                    <Text
                        label='Nome do Responsável pela Inscrição'
                        value={this.state.filters.nameResponsible}
                        name='nameResponsible'
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item sm={12} lg={4}>
                    <Text
                        label='Email do Responsável pela Inscrição'
                        value={this.state.filters.emailResponsible}
                        name='emailResponsible'
                        onChange={this.handleChange}
                    />
                </Grid>                    
                <Grid item sm={12} lg={2}>
                    <Boolean
                        label="Com Responsável pela Inscrição"
                        name='withResponsible'
                        value={this.state.filters.withResponsible}
                        onChange={this.handleChange}
                    />
                </Grid>
            </>
        )
    }
}

export default ResponsavelInscricaoFilter;