export const optionsAdminDep = [
    { value: 'MUNICIPAL', label: 'Municipal' },
    { value: 'ESTADUAL', label: 'Estadual' },
    { value: 'FEDERAL', label: 'Federal' },
];

export const optionsAdminDepWithoutFederal = [
    { value: 'MUNICIPAL', label: 'Municipal' },
    { value: 'ESTADUAL', label: 'Estadual' },
]

export const patternAdminDep = dep => {
    return dep === "MUNICIPAL" ? "Municipal":
            dep === "ESTADUAL" ? "Estadual" :
            dep === "FEDERAL" ? "Federal" : "";
}

export const patternDependencia = deps => {
    let array = [];
    deps.forEach((dep) => {
        array.push({
            qtdEscolasInscritasTotal: dep.inscricao.qtdEscolasInscritasTotal,
            qtdAlunosPrevistosTotal: dep.inscricao.qtdAlunosPrevistosTotal,
            qtdAlunosParticipantesTotal: dep.inscricao.qtdAlunosParticipantesTotal,
            evasaoTotal: dep.inscricao.evasaoTotal,
            dependenciaAdministrativa: dep.dependenciaAdministrativa,
            qtdMaximaAlunosClassificaveisMirim1: dep.inscricao.mirim1.qtdMaximaAlunosClassificaveis,
            qtdMaximaAlunosClassificaveisMirim2: dep.inscricao.mirim2.qtdMaximaAlunosClassificaveis,
            qtdMaximaAlunosClassificaveis: dep.inscricao.qtdMaximaAlunosClassificaveis,
            qtdAlunosInscritosFase2Mirim1: dep.inscricao.qtdAlunosInscritosFase2Mirim1,
            qtdAlunosInscritosFase2Mirim2: dep.inscricao.qtdAlunosInscritosFase2Mirim2,
            qtdAlunosInscritosFase2: dep.inscricao.qtdAlunosInscritosFase2,
            qtdAlunosParticipantesFase2Mirim1: dep.inscricao.qtdAlunosParticipantesFase2Mirim1,
            qtdAlunosParticipantesFase2Mirim2: dep.inscricao.qtdAlunosParticipantesFase2Mirim2,
            qtdAlunosParticipantesFase2: dep.inscricao.qtdAlunosParticipantesFase2,
        });
    })
    return array;
}