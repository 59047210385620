import React from 'react';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        margin: theme.spacing(2, 0, 2),
    },
}));

export const Title = ({ children, variant, className }) => {
  const classes = useStyles();
  return ( <Typography variant={ variant ?? "h5" } gutterBottom className={clsx(classes.title, className)} >{children}</Typography> );
}

export default Title;