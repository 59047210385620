export const optionsSexo = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Feminino' },
];

export const optionsTipoProva = [
    { value: 'AMPLIADA', label: 'Prova Ampliada' },
    { value: 'BRAILE', label: 'Prova Braile' },
    { value: 'NORMAL', label: 'Prova Normal' },
];

export const optionsNivel = [
    { value: 'MIRIM_1', label: 'Mirim 1' },
    { value: 'MIRIM_2', label: 'Mirim 2' },
];

export const optionsAnoLetivo = [
    { value: 'SEGUNDO', label: 'Segundo Ano' },
    { value: 'TERCEIRO', label: 'Terceiro Ano' },
    { value: 'QUARTO', label: 'Quarto Ano' },
    { value: 'QUINTO', label: 'Quinto Ano' },
];

export const optionsTermo = [
    { value: 'VALIDO', label: 'Válido' },
    { value: 'PENDENTE', label: 'Enviado' },
    { value: 'INVALIDO', label: 'Inválido' },
];

export const patternTipoProva = dep => {
    return dep === "AMPLIADA" ? "Ampliada":
            dep === "BRAILE" ? "Braile" :
            dep === "NORMAL" ? "Normal" : "";
}

export const patternNivel = dep => {
    return dep === "MIRIM_1" ? "Mirim 1":
            dep === "MIRIM_2" ? "Mirim 2" : "";
}

export const patternAnoLetivo = dep => {
    return dep === "SEGUNDO" ? "Segundo Ano":
            dep === "TERCEIRO" ? "Terceiro Ano" :
            dep === "QUARTO" ? "Quarto Ano" :
            dep === "QUINTO" ? "Quinto Ano" : "";
}

export const patternSexo = dep => {
    return dep === "M" ? "Masculino":
            dep === "F" ? "Feminino" : "";
}

export const patternTermo = termo => {
    return termo === "VALIDO" ? "Válido" :
            termo === "PENDENTE" ? "Enviado" : 
            termo === "INVALIDO" ? "Inválido" : "";
}