import API from './API';
import { API as APIService } from './API';

const path = `escola`;

const SchoolService = {
    // FILTROS
    filters: async filters => await API.filters(path, filters),

    // EXPORTAR
    export: async filters => await API.export(path, filters),

    // LISTAR TODOS
    list: async () => await API.list(path),

    // LISTAR UM
    find: async id => await API.find(path, id),

    // BUSCAR NA PÁGINA PÚBLICA PARA INSCRIÇÃO
    buscarParaInscricao: async id => await API.find(`${path}/${id}/inscricao`, ''),

    // ATUALIZAR SENHA
    atualizarSenha: async (id, school) => await APIService.put(`${path}/${id}/senha`, school),

    // BUSCAR RESPONSAVEL
    findResponsavel: async (id) => await API.list(`${path}/${id}/responsavel`),

    // EDITAR RESPONSAVEL
    editResponsavel: async (id, responsavel) => await API.edit(`${path}/${id}/responsavel`, '', responsavel),

    // EDITAR
    edit: async (id, school) => await API.edit(path, id, school),

    // ADICIONAR
    add: async school => await API.add(path, school),

    // REMOVER
    remove: async id => await API.remove(path, id),

    // LISTAR EMAILS
    listMails: async id => await APIService.get(`${path}/${id}/emails`),

    // RELATÓRIO PARA ESCOLA
    reportSchool: async (id) => await API.list(`${path}/${id}/relatorio`),
    filtersReportSchool: async (id, filters) => await API.filters(`${path}/${id}/relatorio`, filters),
    exportReportSchool: async (id, filters) => await API.export(`${path}/${id}/relatorio`, filters),

    // VALIDAÇÃO DE EMAIL EXISTENTE
    verificarSeEmailExisteEmOutraEscola: async (email) => await APIService.get(`${path}/verificar-email/${email}`),

    downloadFase2: async (id, habilitar) => await APIService.put(`${path}/${id}/download-fase2/${habilitar}`),
}

export default SchoolService;