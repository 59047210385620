import { API as APIService } from './API';

const path = `tarefas`;

const TarefaService = {

    // OBJETO POSTAL
    iniciarTarefaImportacaoObjetoPostal: async () => await APIService.post(`${path}/objetos-postais`),
    acompanharTarefaImportacaoObjetoPostal: async () => await APIService.get(`${path}/objetos-postais`),

    // STATUS SEDEX
    iniciarTarefaAtualizacaoStatusSedex: async () => await APIService.post(`${path}/status-sedex`),
    acompanharTarefaAtualizacaoStatusSedex: async () => await APIService.get(`${path}/status-sedex`),

}

export default TarefaService;