import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';

export class DadosInscricoes extends Component {
    constructor (props) {
        super(props)
        props.parentRef(this);
        this.state = {
            inscricoes: [],
        }
    }

    // SOMA GERAL PARA ESCOLAS
    sumEscolas = () => this.state.inscricoes.reduce((total, i) => total + i.escolas, 0);

    // SOMA GERAL PARA ALUNOS MIRIM 1
    sumAlunosPrevistosMirim1 = () => this.state.inscricoes.reduce((total, i) => total + i.mirim1, 0);

    // SOMA GERAL PARA ALUNOS MIRIM 2
    sumAlunosPrevistosMirim2 = () => this.state.inscricoes.reduce((total, i) => total + i.mirim2, 0);

    // SOMA GERAL PARA ALUNOS
    sumAlunos = () => this.state.inscricoes.reduce((total, i) => total + i.alunos, 0);

    sumAlunosInscritosFase2Mirim1 = () => this.state.inscricoes.reduce((total, i) => total + i.qtdAlunosInscritosFase2Mirim1, 0);
    sumAlunosInscritosFase2Mirim2 = () => this.state.inscricoes.reduce((total, i) => total + i.qtdAlunosInscritosFase2Mirim2, 0);
    sumAlunosInscritosFase2 = () => this.state.inscricoes.reduce((total, i) => total + i.qtdAlunosInscritosFase2, 0);
    
    sumAlunosParticipantesFase2Mirim1 = () => this.state.inscricoes.reduce((total, i) => total + i.qtdAlunosParticipantesFase2Mirim1, 0);
    sumAlunosParticipantesFase2Mirim2 = () => this.state.inscricoes.reduce((total, i) => total + i.qtdAlunosParticipantesFase2Mirim2, 0);
    sumAlunosParticipantesFase2 = () => this.state.inscricoes.reduce((total, i) => total + i.qtdAlunosParticipantesFase2, 0);

    render () {
        const columns = [
            { label: 'Data de Inscrição', name: 'dataFormatada', func: null, key: false, width: '8', },
            { label: 'Total de Escolas', name: 'escolas', func: null, key: false, width: '10', },
            { label: 'Fase 1 - Mirim 1 - Alunos', name: 'mirim1', func: null, key: false, width: '10', },
            { label: 'Fase 1 - Mirim 2 - Alunos', name: 'mirim2', func: null, key: false, width: '10', },
            { label: 'Fase 1 - Total de Alunos', name: 'alunos', func: null, key: false, width: '10', },
            { label: 'Fase 2 -  Mirim 1 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim1', func: null, key: false, width: '8', },
            { label: 'Fase 2 -  Mirim 2 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim2', func: null, key: false, width: '8', },
            { label: 'Fase 2 - Total de Alunos Inscritos', name: 'qtdAlunosInscritosFase2', func: null, key: false, width: '10', },
            { label: 'Fase 2 -  Mirim 1 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim1', func: null, key: false, width: '8', },
            { label: 'Fase 2 -  Mirim 2 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim2', func: null, key: false, width: '8', },
            { label: 'Fase 2 - Total de Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2', func: null, key: false, width: '10', },
        ];
        
        const actions = [];

        const totals = [
            { column: 0, value: "Total", width: '8', },
            { column: 1, value: this.sumEscolas(), width: '10', },
            { column: 2, value: this.sumAlunosPrevistosMirim1(), width: '10', },
            { column: 3, value: this.sumAlunosPrevistosMirim2(), width: '10', },
            { column: 4, value: this.sumAlunos(), width: '10', },
            { column: 5, value: this.sumAlunosInscritosFase2Mirim1(), width: '8', },
            { column: 6, value: this.sumAlunosInscritosFase2Mirim2(), width: '8', },
            { column: 7, value: this.sumAlunosInscritosFase2(), width: '10', },
            { column: 8, value: this.sumAlunosParticipantesFase2Mirim1(), width: '8', },
            { column: 9, value: this.sumAlunosParticipantesFase2Mirim2(), width: '8', },
            { column: 10, value: this.sumAlunosParticipantesFase2(), width: '10', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.state.inscricoes}
                        actions={actions}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} />
                </Grid>
            </Grid>
        )
    }
}

export default DadosInscricoes;