import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'

import EscolaService from '../../services/Escola'
import { errors } from '../../services/API'

import Dialog from '../../components/Dialog/Dialog'
import Text from '../../components/Inputs/Text/Text'
import Toast from '../../components/Toast/Toast'

import Password from '../../forms/Password/Password'
import { ButtonSave, ButtonCancel } from '../../forms/Buttons/ButtonsForm'

export class DialogChangePassword extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            open: false,
            id: null,
            loading: false,
        }
    }

    // DECLARAÇÕES DE REFERENCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setPassword = p => this.Password = p;

    // AÇÕES MODAL
    buttons = (id) =>
        <>
            <ButtonSave onClick={this.handleAtualizar} />
            <ButtonCancel onBack={this.handleClose} />
        </>

    // FECHAR MODAL
    handleClose = () => {
        this.setState({
            open: false,
            id: null,
            loading: false,
        });
    }

    // SUBMIT
    handleAtualizar = () => {
        this.setState({ loading: true });

        const formPasswordIsValid = this.Password.isValid();

        if (!formPasswordIsValid) {
            this.setState({ loading: false });
            return;
        }

        const school = {
            password: this.Password.state.password,
            passwordTemporario: this.Password.state.passwordTemporario,
        }

        EscolaService.atualizarSenha(this.state.id, school)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Senha Atualizada Com Sucesso.",
                        type: "success",
                        open: true
                    }
                })
            })
            .catch(err => {
                const e = errors(err);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.handleClose());
    }

    render() {
        return (
            <>  
                <Toast parentRef={this.setToast} />
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    closeDialog={this.handleClose}
                    openDialog={this.state.open}
                    title={"Atualizar Senha"}
                    actions={this.buttons()}
                >
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item sm={12} lg={12}>
                            <Text
                                name="username"
                                label="Login"
                                value={this.state.id}
                                readonly={true}
                            />
                        </Grid>
                    </Grid>

                    <Password parentRef={this.setPassword} fullWidth={true} />
                </Dialog>
            </>
        )
    }
}

export default DialogChangePassword;